import { useState, memo } from 'react'

const FormFieldSelect = ({ options, value, onChange, placeholder, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (value) => {
    onChange(value)
    setIsOpen(false)
  }

  return (
    <div className="cart-form-field-wrapper" style={{cursor: 'pointer'}}>
      <div className={`cart-form-field-label cart-form-field-label--active`}>
        {placeholder}
      </div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cart-form-field-wrapper-input ${disabled === true ? 'field-disabled' : ''}`}
      >
        {options.find(option => option.value === value)?.label || placeholder}
      </div>
      <img src="/images/utils/arrow-down.svg" className="cart-form-field-select-icon" />
      <div className={`cart-form-field-options ${isOpen ? 'active' : ''}`}>
        {options.map(option => (
          <div
            key={option.value}
            onClick={() => handleSelect(option.value)}
            className={`cart-form-field-option ${option.value === value ? 'active' : ''}`}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(FormFieldSelect)