import { useRef, memo } from 'react'
import { AddressSuggestions } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import ModalFull from '../../ModalFull'
import { dadataPopularCities } from '../../../data/data'

const CartFormModalCity = ({ isOpen, onClose, currentValue, setData, validateField }) => {
  const suggestionsRef = useRef(null)

  const handleChangeDeliveryCity = (value) => {
    console.log(value)
    if (suggestionsRef.current && value.data) {
      suggestionsRef.current.setInputValue(value.data.city)
      setData(value.data.country, value.data.country_iso_code, value.data.region, value.data.city, value.data.fias_id, value.data.kladr_id, value.data.postal_code)
      validateField(value.data.city)
      onClose()
    }
  }

  const handleSuggest = (value) => {
    if (suggestionsRef.current) {
      suggestionsRef.current.setInputValue(value.city)
      setData(value.country, value.country_iso_code, value.region, value.city, value.fias_id, value.kladr_id, value.postal_code)
      validateField(value.city)
      onClose()
    }
  }

  return (
    <ModalFull heading="Выберите город" isOpen={isOpen} onClose={onClose}>
      <p className="mb-0">Вы можете выбрать ещё 150 000 населённых пунктов по всей Российской Федерации</p>
      <AddressSuggestions
        ref={suggestionsRef}
        filterFromBound="country"
        filterToBound="settlement"
        autoload={true}
        filterLocations={[
          { country_iso_code: "ru" },
          { country_iso_code: "by" },
          { country_iso_code: "az" },
          { country_iso_code: "am" },
          { country_iso_code: "kz" },
          { country_iso_code: "kg" },
          { country_iso_code: "md" },
          { country_iso_code: "tj" },
          { country_iso_code: "tm" },
          { country_iso_code: "uz" },
          { country_iso_code: "ge" },
        ]}
        token={import.meta.env.VITE_DADATA_API_KEY}
        value={{ value: currentValue }}
        onChange={(suggestion) => handleChangeDeliveryCity(suggestion)}
        inputProps={{
          className: "cart-form-field-wrapper-input",
          placeholder: "Начните вводить ваш город и выберите его из списка",
        }}
      />
      {/* <div className="button-big-primary-wide" onClick={onClose}>Подтвердить</div> */}
      <div className="cart-form-modal-popular-cities">
        {dadataPopularCities.map((value, index) => (
          <div key={index} onClick={() => handleSuggest(value)} className="cart-form-modal-popular-city">{value.city}</div>
        ))}
      </div>
    </ModalFull>
  )
}

export default memo(CartFormModalCity)