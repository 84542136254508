import { memo } from 'react'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

const FormFieldDatePicker = ({ value, onChange, placeholder, isInvalid, validateField }) => {
  const dateFromString = (dateString) => {
    const [day, month, year] = dateString.split('.')
    return new Date(year, month - 1, day)
  }

  return (
    <div className="cart-form-field-wrapper">
      <div className={`cart-form-field-label cart-form-field-label--active`}>
        {placeholder}
      </div>
      <DatePicker
        value={value ? dateFromString(value) : null}
        onChange={(value) => onChange(value.toLocaleDateString('ru-RU'))}
        onBlur={(e) => validateField ? validateField(e.target.value) : true}
        placeholder={placeholder}
        className={`cart-form-field-wrapper-input ${isInvalid === true ? 'field-invalid' : ''}`}
        clearIcon={null}
        format="dd.MM.yyyy"
      />
    </div>
  )
}

export default memo(FormFieldDatePicker)