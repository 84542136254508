import useTitle from "../hooks/useTitle"
import useDescription from "../hooks/useDescription"
import { Link } from "react-router-dom"

const NotFound = () => {
  useTitle('404')
  useDescription('Страница не найдена')

  return (
    <div className="container">
      <div className="wrapper-404">
        <div className="heading-h1">404</div>
        <p className="text-404">Кажется, страницы которую вы ищете не существует</p>
        <Link to="/" className="button-big-primary-wide">Перейти на главную страницу</Link>
      </div>
    </div>
  )
}

export default NotFound