import { SLUG_ATTR_BREWING_METHOD } from '../utils/enums'

const ProductBrewingMethods = ({ product }) => {
  const brewing_methods = product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_BREWING_METHOD) ? product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_BREWING_METHOD).options : false

  if (brewing_methods === false) {
    return null
  }

  const getIcon = (slug) => {
    if (slug === "capsule") return `${slug}.svg`
    return `${slug}.png`
  }

  return (
    <div className="catalog-product-specs big">
      <div className="catalog-product-spec">
        <div className="catalog-product-spec-label">Способы заваривания</div>
        <div className="product-brewing-methods">
          {brewing_methods.map((method, key) => (
            <div key={method.id} className="product-brewing-method">
              <img src={`/images/icons-brewing-methods/${getIcon(method.slug)}`} alt="Brewing method" />
              <div>{method.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductBrewingMethods