import { useGetUserQuery } from '../../features/backend/backendApiSlice'
import Product from '../Product'

const ProfileFavorites = ({ active }) => {
  const { data: user } = useGetUserQuery()

  return (
    <div className={`${!active ? 'display-none' : ''}`}>
      <div className="heading-h2">Избранные товары</div>
      <div className="catalog-products-container">
        {user?.customer?.products?.length > 0 ? user.customer.products.map((product) => (
          <Product key={product.id} product={product} />
        )) : 'Пока нет избранных товаров'}
      </div>
    </div>
  )
}

export default ProfileFavorites