import { useState } from 'react'
import { useGetUserQuery } from '../../features/backend/backendApiSlice'
import useForm from '../../hooks/useForm'
import FormFieldText from '../Form/FormFieldText'
import FormFieldImage from '../Form/FormFieldImage'
import FormFieldDatePicker from '../Form/FormFieldDatePicker'
import FormFieldSelect from '../Form/FormFieldSelect'
import { updateCustomer } from '../../utils/api'
import { PartySuggestions, BankSuggestions  } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'

const ProfileData = ({ active }) => {
  const { data: user, refetch } = useGetUserQuery()
  const [recentlyUpdated, setRecentlyUpdated] = useState(false)

  const {
    fields,
    invalidFields,
    validateFieldMemoized,
    setFieldMemoized,
    setFieldMultiple,
    handleSubmit,
    isSubmitting,
  } = useForm({
    last_name: user.customer?.last_name ?? '',
    first_name: user.customer?.first_name ?? '',
    patronymic: user.customer?.patronymic ?? '',
    email: user.customer?.email ?? '',
    birthdate: user.customer?.birthdate ? new Date(user.customer.birthdate).toLocaleDateString('ru-RU') : '',
    avatar: user.customer?.avatar,
    legal_type: user.customer?.legal_type ?? 1,
    legal_org: user.customer?.legal_org ?? '',
    legal_ogrnip: user.customer?.legal_ogrnip ?? '',
    legal_inn: user.customer?.legal_inn ?? '',
    legal_kpp: user.customer?.legal_kpp ?? '',
    legal_acc: user.customer?.legal_acc ?? '',
    legal_corr_acc: user.customer?.legal_corr_acc ?? '',
    legal_bik: user.customer?.legal_bik ?? '',
  }, {
    password_confirmation: (value) => fields.password && value != fields?.password,
  }, async (fields) => {
    const response = await updateCustomer(fields)
    if (response?.id == user.id) {
      setRecentlyUpdated(true)
      setTimeout(() => setRecentlyUpdated(false), 2000)
    }
    await refetch()
  })

  const handleChangeLegalOrg = (value) => {
    if (value.data) {
      // suggestionsRef.current.setInputValue(value.value)
      setFieldMultiple({
        legal_org: value.value,
        legal_ogrnip: value.data.ogrn,
        legal_inn: value.data.inn,
        legal_kpp: value.data.kpp,
      })
      // handleValidate(value.value)
    }
  }

  const handleChangeLegalBik = (value) => {
    if (value.data) {
      // suggestionsRef.current.setInputValue(value.value)
      setFieldMultiple({
        legal_bik: value.data.bic,
        legal_corr_acc: value.data.correspondent_account,
      })
      // handleValidate(value.value)
    }
  }

  return (
    <div className={`${!active ? 'display-none' : ''}`}>
      <div className="heading-h2">Личные данные</div>
      <div className="profile-data-form mb-40">
        <form onSubmit={handleSubmit}>
          <div className="form-fields-container-1col mb-20">
            <FormFieldImage
              id="profile-data-avatar-input"
              current={user.customer?.avatar}
              onChange={setFieldMemoized('avatar')}
            />
          </div>
          <div className="form-fields-container-2col mb-20">
            <FormFieldText
              value={fields.last_name ?? ''}
              isInvalid={invalidFields.last_name}
              placeholder={'Фамилия'}
              onChange={setFieldMemoized('last_name')}
              validateField={validateFieldMemoized('last_name')}
            />
            <FormFieldText
              value={fields.first_name ?? ''}
              isInvalid={invalidFields.first_name}
              placeholder={'Имя'}
              onChange={setFieldMemoized('first_name')}
              validateField={validateFieldMemoized('first_name')}
            />
            <FormFieldText
              value={fields.patronymic ?? ''}
              isInvalid={invalidFields.patronymic}
              placeholder={'Отчество'}
              onChange={setFieldMemoized('patronymic')}
              validateField={validateFieldMemoized('patronymic')}
            />
            <FormFieldText
              value={user.customer?.phone ?? ''}
              placeholder={'Номер телефона'}
              disabled={true}
            />
            <FormFieldText
              value={fields.email ?? ''}
              isInvalid={invalidFields.email}
              placeholder={'Электронная почта'}
              onChange={setFieldMemoized('email')}
              validateField={validateFieldMemoized('email')}
            />
            <FormFieldDatePicker
              value={fields.birthdate ?? ''}
              isInvalid={invalidFields.birthdate}
              placeholder={'Дата рождения'}
              onChange={setFieldMemoized('birthdate')}
              validateField={validateFieldMemoized('birthdate')}
            />
            <FormFieldSelect
              value={fields.legal_type ?? 1}
              isInvalid={invalidFields.legal_type}
              placeholder={'Статус'}
              onChange={setFieldMemoized('legal_type')}
              options={[
                { value: 1, label: 'Физическое лицо' },
                { value: 2, label: 'ИП' },
                { value: 3, label: 'Организация' },
              ]}
            />

            {/* <FormFieldText
              value={fields.legal_org ?? ''}
              isInvalid={invalidFields.legal_org}
              placeholder={'Наименование организации'}
              onChange={setFieldMemoized('legal_org')}
              validateField={validateFieldMemoized('legal_org')}
              className={fields.legal_type == 1 ? 'display-none' : ''}
            /> */}
            <div className={`cart-form-field-wrapper ${fields.legal_type == 1 ? 'display-none' : ''}`}>
              <div className={`cart-form-field-label ${active ? 'cart-form-field-label--active' : ''}`}>
              Наименование организации
              </div>
              <PartySuggestions
                // ref={suggestionsRef}
                // filterToBound='house'
                token={import.meta.env.VITE_DADATA_API_KEY}
                value={{value: fields.legal_org ?? ''}}
                onChange={handleChangeLegalOrg}
                inputProps={{
                  // name: 'full_address',
                  placeholder: 'Наименование организации',
                  className: `cart-form-field-wrapper-input `, // ${isInvalid === true ? 'field-invalid' : ''}
                  onKeyUp: (e) => {
                    // handleFillDeliveryAddress(e.target.value);
                  },
                  // onBlur: (e) => handleValidate(e.target.value),
                }}
              />
            </div>
            
            <FormFieldText
              value={fields.legal_ogrnip ?? ''}
              isInvalid={invalidFields.legal_ogrnip}
              placeholder={'ОГРН / ОГРНИП'}
              onChange={setFieldMemoized('legal_ogrnip')}
              validateField={validateFieldMemoized('legal_ogrnip')}
              className={fields.legal_type == 1 ? 'display-none' : ''}
            />
            <FormFieldText
              value={fields.legal_inn ?? ''}
              isInvalid={invalidFields.legal_inn}
              placeholder={'ИНН'}
              onChange={setFieldMemoized('legal_inn')}
              validateField={validateFieldMemoized('legal_inn')}
              className={fields.legal_type == 1 ? 'display-none' : ''}
            />
            <FormFieldText
              value={fields.legal_kpp ?? ''}
              isInvalid={invalidFields.legal_kpp}
              placeholder={'КПП'}
              onChange={setFieldMemoized('legal_kpp')}
              validateField={validateFieldMemoized('legal_kpp')}
              className={fields.legal_type == 1 ? 'display-none' : ''}
            />
            <FormFieldText
              value={fields.legal_acc ?? ''}
              isInvalid={invalidFields.legal_acc}
              placeholder={'Расчётный счёт'}
              onChange={setFieldMemoized('legal_acc')}
              validateField={validateFieldMemoized('legal_acc')}
              className={fields.legal_type == 1 ? 'display-none' : ''}
            />
            
            <div className={`cart-form-field-wrapper ${fields.legal_type == 1 ? 'display-none' : ''}`}>
              <div className={`cart-form-field-label ${active ? 'cart-form-field-label--active' : ''}`}>
                БИК
              </div>
              <BankSuggestions
                // ref={suggestionsRef}
                // filterToBound='house'
                token={import.meta.env.VITE_DADATA_API_KEY}
                value={{value: fields.legal_bik ?? ''}}
                onChange={handleChangeLegalBik}
                inputProps={{
                  // name: 'full_address',
                  placeholder: 'БИК',
                  className: `cart-form-field-wrapper-input `, // ${isInvalid === true ? 'field-invalid' : ''}
                  onKeyUp: (e) => {
                    // handleFillDeliveryAddress(e.target.value);
                  },
                  // onBlur: (e) => handleValidate(e.target.value),
                }}
              />
            </div>
            {/* <FormFieldText
              value={fields.legal_bik ?? ''}
              isInvalid={invalidFields.legal_bik}
              placeholder={'БИК'}
              onChange={setFieldMemoized('legal_bik')}
              validateField={validateFieldMemoized('legal_bik')}
              className={fields.legal_type == 1 ? 'display-none' : ''}
            /> */}

            <FormFieldText
              value={fields.legal_corr_acc ?? ''}
              isInvalid={invalidFields.legal_corr_acc}
              placeholder={'Корр. счёт'}
              onChange={setFieldMemoized('legal_corr_acc')}
              validateField={validateFieldMemoized('legal_corr_acc')}
              className={fields.legal_type == 1 ? 'display-none' : ''}
            />
            
          </div>
          <div className="form-fields-container-1col mb-20">
            <div className="form-buttons">
              <button type="submit" className={`button-big-primary-wide ${isSubmitting ? 'disabled' : ''}`}>
                {isSubmitting ? 'Сохраняем...' : (recentlyUpdated ? 'Сохранено' : 'Сохранить изменения')}
              </button>
              {/* <div className="form-button-dismiss" onClick={() => setIsFormVisible(false)}>Отменить</div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProfileData