import Map from '../components/Map'
import EmailForm from '../components/EmailForm'
import FeaturedSlider from '../components/FeaturedSlider'
import FeaturedProducts from '../components/FeaturedProducts'
import useTitle from "../hooks/useTitle"

const Index = () => {
  useTitle('Главная страница')

  return (
    <>
      <FeaturedSlider />
      <div className="container container-homepage">
        <FeaturedProducts />
        <div className="heading-big">Что такое<br />Rockets.coffee?</div>
        <div className="about-us-block mb-0">
          <div className="flex-padding"></div>
          <div>
            <div className="heading-h2-branded">Мы создаём экосистему вокруг кофе</div>
            <p className="gray-text about-us-text">Это и выбор зеленого зерна, и обжарка, и обучение, и вкусная чашка у вас дома или в любимой кофейне. Наша главная цель — сделать хороший кофе доступным, мы несем эту культуру в рестораны и кофейни с 2014 года</p>
          </div>
        </div>
        <div className="about-us-block">
          <div>
            <div className="about-us-image">
              <img src="/images/home-page/img-1.webp" alt="" />
            </div>
          </div>
          <div>
            <div className="about-us-image">
              <img src="/images/home-page/img-2.webp" alt="" />
            </div>
          </div>
        </div>
        <div className="about-us-block">
          <div className="flex-padding"></div>
          <div>
            <div className="heading-h2-branded">Разные ростеры для разного кофе</div>
            <p className="gray-text about-us-text">Высокоточный конвекционный ростер IMF мы используем для обжарки зерна под эспрессо — он дает более равномерную обжарку. На кондукционном ростере Giesen мы обжариваем всю линейку фильтр-кофе и избранные моносорта</p>
          </div>
        </div>
        <div className="about-us-image-big">
          <img src="/images/home-page/img-3.webp" alt="" />
        </div>
        <div className="about-us-block">
          <div className="flex-padding"></div>
          <div>
            <div className="heading-h2-branded">Зерно, оборудование, знания</div>
            <p className="gray-text about-us-text">Три составляющие отличной чашки кофе. Записывайтесь на мастер-классы и курсы или становитесь нашими партнерами во вкладке «сотрудничество»</p>
          </div>
        </div>
        <div className="about-us-block">
          <div>
            <div className="about-us-image">
              <img src="/images/home-page/img-4.webp" alt="" />
            </div>
          </div>
          <div>
            <div className="about-us-image">
              <img src="/images/home-page/img-5.webp" alt="" />
            </div>
          </div>
        </div>
        <div className="about-us-block mb-50">
          <div className="flex-padding"></div>
          <div>
            <div className="heading-h2-branded">Выбор зелёного зерна</div>
            <p className="gray-text about-us-text">Ежегодно мы отправляемся в Кению, Колумбию, Эфиопию и другие страны. Это помогает нам создать свой уникальный вкус</p>
          </div>
        </div>
        <Map />
      </div>
      <div className="home-page-banner mb-40">
        <img src="/images/home-page/banner.webp" alt="" className="home-page-banner-desktop" />
        <img src="/images/home-page/banner-mobile.png" className="home-page-banner-mobile" alt="" />
      </div>
      <div className="wrapper-homepage-white">
        <div className="container container-homepage">
          <div className="about-us-block mb-40">
            <div className="flex-padding"></div>
            <div>
              <div className="heading-h2-branded">Строгий входящий и исходящий контроль качества</div>
              <p className="gray-text about-us-text">Мы разработали процедуру контроля входящего сырья и готового продукта в соответствии с международными стандартами оценки кофе</p>
            </div>
          </div>
          <div className="about-us-block mb-40">
            <div>
              <div className="about-us-image">
                <img src="/images/home-page/img-6.jpg" alt="" />
              </div>
            </div>
            <div>
              <div className="about-us-image">
                <img src="/images/home-page/img-7.jpg" alt="" />
              </div>
            </div>
          </div>
          <EmailForm />
        </div>
      </div>
    </>
  )
}

export default Index