import { useState, useEffect } from 'react'
import { useGetUserMcrmQuery } from '../../features/backend/backendApiSlice'
import { loyaltyStages } from '../../data/data'

const ProfileBonuses = ({ active }) => {
  const { data: user, isError, isLoading, isSuccess, refetch, isFetching } = useGetUserMcrmQuery()

  console.log(user)

  const [userStage, setUserStage] = useState(loyaltyStages[0]);

  useEffect(() => {
    if (user?.userdata?.segments) {
      loyaltyStages.forEach((stage, index) => {
        if (user.userdata.segments.includes(stage.name)) {
          setUserStage(loyaltyStages[index])
        }
      })
    }
  }, [user])

  return (
    <div className={`${!active ? 'display-none' : ''}`}>
      <div className="heading-h2">Бонусная система</div>
      <div className="profile-bonuses-info">
        <div className="profile-bonuses-info-block">
          <div>Ваш уровень</div>
          <div>{userStage.name}</div>
        </div>
        <div className="profile-bonuses-info-block">
          <div>Бонусный счёт</div>
          <div>
            {user?.balance?.summ} {user?.balance?.summ >= 11 && user?.balance?.summ <= 14 ? "баллов"
              : user?.balance?.summ % 10 == 1 ? "балл"
              : user?.balance?.summ % 10 == 2 || user?.balance?.summ % 10 == 3 || user?.balance?.summ % 10 == 4 ? "балла"
              : "баллов"}
          </div>
        </div>
      </div>
      <div className="profile-bonuses-stage-current">
        <div className="profile-bonuses-stage-current__heading">Cashback</div>
        <div>
          <div className="profile-bonuses-stage-current__value">{userStage.discount}%</div>
          <div className="profile-bonuses-stage-current__note">от суммы выполненного заказа</div>
        </div>
        {user?.userdata?.osmi_link && <div className="profile-bonuses-stage-current__osmi-link">
          <a href={user.userdata.osmi_link} target="_blank"><img src="/images/icons/app-apple.png" alt="" /></a>
          <a href={user.userdata.osmi_link} target="_blank"><img src="/images/icons/app-google.png" alt="" /></a>
        </div>}
      </div>
      <div className="profile-bonuses-stages">
        <div className="profile-bonuses-stages__heading">Увеличьте свой кэшбэк</div>
        <div className="profile-bonuses-stages-other">
          {loyaltyStages.filter(stage => stage.name !== userStage.name).map((stage, index) => (
            <div key={index} className="profile-bonuses-stage">
              <div className="profile-bonuses-stage__heading">{stage.name}</div>
              <div className="profile-bonuses-stage__value">{stage.discount}%</div>
              <div className="profile-bonuses-stage__note">Кэшбэк при общей сумме покупок от {stage.threshold} руб.</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProfileBonuses