import { useState } from "react"
import CatalogTabNav from "./CatalogTabNav"
import CatalogFiltersQuick from "./CatalogFiltersQuick"
import CatalogFiltersFull from "./CatalogFiltersFull"
import CatalogSorter from "./CatalogSorter"
import { catalogFiltersCleared } from '../../features/catalogFilters/catalogFiltersSlice'
import { useDispatch } from 'react-redux'
import { SLUG_FILTER_PERKS } from '../../utils/enums'

const CatalogControls = ({ category, products }) => {
  const [isFiltersFullVisible, setIsFiltersFullVisible] = useState(false)
  const [isFiltersMobileVisible, setIsFiltersMobileVisible] = useState(false)

  const dispatch = useDispatch()

  const handleClearFilters = () => {
    dispatch(catalogFiltersCleared())
  }

  const options = products.map(product => product.attributes.find(a => a.attribute.slug === SLUG_FILTER_PERKS)?.options || []).flat()
  const filtersQuick = [...new Set(options.map(option => option?.id))].map(id => options.find(option => option?.id === id))

  let filtersFull = products.reduce((acc, product) => {
    product.attributes.forEach(attribute => {
      if (attribute.attribute.slug != SLUG_FILTER_PERKS && attribute.attribute.is_filterable == 1) {
        if (!acc.find(item => item.attribute.slug == attribute.attribute.slug)) {
          acc.push({
            attribute: attribute.attribute,
            options: []
          })
        }

        const item = acc.find(item => item.attribute.slug == attribute.attribute.slug)

        attribute.options.forEach(option => {
          if (!item.options.find(itemOption => itemOption.id == option.id)) {
            item.options.push(option)
          }
        })
      }
    })
    return acc
  }, [])
  filtersFull = filtersFull.filter(filter => filter.options.length > 1)
  filtersFull.sort((a, b) => a.attribute.sort_order - b.attribute.sort_order)

  return (
    <>
      <CatalogTabNav />
      {(filtersQuick.length > 0 || filtersFull.length > 0) && <div className="catalog-filters-container">
        <CatalogFiltersQuick category={category} filters={filtersQuick} />
        <div className="catalog-filters-toggle-desktop hide-xs hide-sm hide-md">
          {filtersFull.length > 0 ? <div onClick={() => setIsFiltersFullVisible(!isFiltersFullVisible)}>{!isFiltersFullVisible ? 'Показать расширенный фильтр' : 'Скрыть расширенный фильтр'}</div> : <div></div>}
          {!isFiltersFullVisible && <div className="catalog-filters-reset-desktop" onClick={handleClearFilters}>Сбросить все фильтры</div>}
        </div>
        {isFiltersFullVisible && <div className="catalog-filters-full-wrapper hide-xs hide-sm hide-md">
          <CatalogFiltersFull category={category} filters={filtersFull} />
          <div className="catalog-filters-controls-desktop">
            <div></div>
            <div className="catalog-filters-reset-desktop" onClick={handleClearFilters}>Сбросить все фильтры</div>
          </div>
        </div>}
      </div>}
      <div className="catalog-filters-container-mobile hide-lg hide-xl hide-xxl">
        <CatalogSorter />
        <div className="catalog-filters-toggle-mobile" onClick={() => setIsFiltersMobileVisible(!isFiltersMobileVisible)}>
          <img src="/images/icons/filter.png" alt="" />
        </div>
        <div className={`catalog-filters-full-container-mobile ${isFiltersMobileVisible ? 'display-block' : ''}`}>
          <div className="catalog-filters-full-container-mobile__header">
            <div>Фильтры</div>
            <div onClick={() => setIsFiltersMobileVisible(!isFiltersMobileVisible)}><img src="/images/icons/close.png" alt="" /></div>
          </div>
          <div className="catalog-filters-full-wrapper">
            <CatalogFiltersFull category={category} filters={filtersFull} />
            <div className="catalog-filters-full-mobile-buttons">
              <div className="button-big-secondary" onClick={handleClearFilters}>Сбросить все фильтры</div>
              <div className="button-big-primary" onClick={() => setIsFiltersMobileVisible(!isFiltersMobileVisible)}>Применить</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default CatalogControls
