import { Link } from "react-router-dom"

const CatalogTabLink = ({ pathname, path, label, className }) => {
  return (
    <Link
      to={`/${path}`}
      className={`catalog-tab-nav-link ${pathname === `/${path}` ? 'active' : ''} ${className ? className : ''}`}
    >
      {label}
    </Link>
  )
}


export default CatalogTabLink
