import { useState } from 'react'
import { useGetProductsQuery } from '../../features/backend/backendApiSlice'
import { cartItemAdded, cartItemsCleared } from '../../features/cartItems/cartItemsSlice'
import { isOnSale } from '../../utils/functions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ProfileOrderItem from './ProfileOrderItem'

const ProfileOrder = ({ order }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isExpanded, setIsExpanded] = useState(false)

  const itemsAvailable = order.variations.filter(variation => variation.product.is_published == 1 && variation.product.in_stock == 1).length

  let discount = 0
  if (order.coupons.length > 0) {
    order.coupons.forEach(coupon => {
      discount += coupon.pivot.discount
    })
  }
  if (order.gift_card_transactions.length > 0) {
    order.gift_card_transactions.forEach(gift_card_transaction => {
      discount += gift_card_transaction.amount * -1
    })
  }
  if (order.bonuses_transactions.length > 0) {
    order.bonuses_transactions.forEach(bonuses_transaction => {
      discount += bonuses_transaction.amount * -1
    })
  }

  const handleRepeatOrder = () => {
    if (itemsAvailable == 0) {
      return
    }

    dispatch(cartItemsCleared())

    order.variations.forEach(variation => {
      if (variation.product.is_published == 1 && variation.product.in_stock == 1) {
        dispatch(cartItemAdded({
          product_id: variation.product.id,
          variation: variation,
          qty: variation.pivot.qty,
          is_on_sale: isOnSale(variation),
        }))
      }
    })

    navigate('/cart')
  }

  return (
    <div className="profile-order">
      <div className="profile-order-summary">
        <div className="profile-order-summary__data">
          <div>№{order.number}</div>
          <div>{new Date(order.created_at).toLocaleDateString('ru-RU')}</div>
          <div><span>{discount / 100} <span className="commissioner-400">₽</span></span></div>
          <div><span>{order.total / 100} <span className="commissioner-400">₽</span></span></div>
          <div><span className="branded-text">{order.statuses[0]?.name}</span></div>
        </div>
        <div className="profile-order-summary__expand" onClick={() => setIsExpanded(!isExpanded)}>
          <img src="/images/utils/plus.svg" alt="" />
        </div>
      </div>
      <div className="profile-order-summary--mobile">
        <div className="profile-order-summary__data--mobile">
          <div className="profile-order-summary__data-block--mobile">
            <div className="black">№{order.number}</div>
            <div className="profile-order-summary__data-subblock--mobile">
              <div className="gray">Скидка</div>
              <div className="black"><span>{discount / 100} <span className="commissioner-400">₽</span></span></div>
            </div>
          </div>
          <div className="profile-order-summary__data-block--mobile">
            <div className="black">{new Date(order.created_at).toLocaleDateString('ru-RU')}</div>
            <div className="profile-order-summary__data-subblock--mobile">
              <div className="gray">Сумма</div>
              <div className="black"><span>{order.total / 100} <span className="commissioner-400">₽</span></span></div>
            </div>
          </div>
        </div>
        <div className="profile-order-summary__data-block-expand--mobile">
          <div className="profile-order-summary__expand" onClick={() => setIsExpanded(!isExpanded)}>
            <img src="/images/utils/plus.svg" alt="" />
          </div>
          <div><span className="branded-text">{order.statuses[0]?.name}</span></div>
        </div>
      </div>
      <div className={`${!isExpanded ? 'display-none' : ''}`}>
        <div className="profile-order-products">
          {order.variations.map((variation, index) => <ProfileOrderItem key={index} variation={variation} />)}
        </div>
        <div className="profile-order-details">
          <div>Способ доставки: <span>{order.shipping_options[0]?.name}</span></div>
          <div>Способ оплаты: <span>{order.payment_method.name}</span></div>
        </div>
        <div className="profile-order-details">
          <div>Сумма заказа: <span>{(order.total + discount) / 100} <span className="commissioner-400">₽</span></span></div>
          <div>Скидка: <span>{discount / 100} <span className="commissioner-400">₽</span></span></div>
          <div>Итого: <span>{order.total / 100} <span className="commissioner-400">₽</span></span></div>
          <div><div className={`link-action ${itemsAvailable == 0 ? 'disabled' : ''}`} onClick={handleRepeatOrder}>{itemsAvailable > 0 ? 'Повторить заказ' : 'Нет в наличии'}</div></div>
        </div>
      </div>
    </div>
  )
}

export default ProfileOrder