import { useState, useCallback } from 'react'
import { contactsMapPoints } from '../../data/data'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import ContactsModalForm from './ContactsModalForm'
import useTitle from "../../hooks/useTitle"
import ContactsMap from '../../components/ContactsMap'

const ContactsView = () => {
  useTitle('Контакты')

  const [activeTab, setActiveTab] = useState(1)
  
  const [modalOpen, setModalOpen] = useState(null)
  const closeModal = useCallback(() => setModalOpen(null), [])

  return (
    <>
      <ContactsModalForm
        isOpen={modalOpen == 'form'}
        onClose={closeModal}
      />
      <div className="container">
        <div className="heading-h1">Контакты</div>
        <div className="tabs-container tabs-container-contacts mb-40">
          <div className="tabs">
            <div className={`tab-link ${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>Москва</div>
            <div className={`tab-link ${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>Республики: Дагестан, Чеченская, Ингушетия, Северная Осетия</div>
            <div className={`tab-link ${activeTab == 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>Геленджик, Анапа, Новороссийск</div>
          </div>
          <div className="tabs-content">
            {activeTab == 1 && <>
              <p className="gray-text">Для звонков по России</p>
              <p className="mb-40"><strong>8 800 777-04-14</strong></p>
            </>}
            {[2, 3].includes(activeTab) && <p className="mb-40">Для связи с дистрибьютером в данном регионе заполните форму обратной связи ниже</p>}
            <p className="gray-text">Почта для общих вопросов</p>
            <p className="mb-40"><strong>info@rockets.coffee</strong></p>
            <p className="gray-text sm-text mb-40">Если у вас есть вопросы или вы хотите предложить сотрудничество воспользуйтесь формой <span className="link-action-gray" onClick={() => setModalOpen('form')}>обратной связи</span></p>
            {activeTab == 1 && <p className="gray-text sm-text link-action-gray" onClick={() => setModalOpen('form')}>Стать дистрибьютером</p>}
          </div>
        </div>
      </div>
      <div className="contacts-map-container">
        <YMaps>
          <ContactsMap activeTab={activeTab} />
        </YMaps>
      </div>
    </>
  )
}

export default ContactsView