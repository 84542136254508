const ProfileReview = ({ review }) => {
  return (
    <div className="profile-review">
      <div className="profile-review-header">
        <div className="profile-review-meta">
          <div className="profile-review-meta__type">{review.review_category.name}</div>
          <div className="profile-review-meta__date">{new Date(review.created_at).toLocaleDateString('ru-RU')}</div>
        </div>
        <div className="profile-review-rating">
          {[1,2,3,4,5].map((rating) => (
            <img
              key={rating}
              src={`/images/utils/star-${review.rating >= rating ? 'blue' : 'gray'}.svg`}
            />
          ))}
        </div>
      </div>
      <div className="profile-review-body">
        {review.text}
        
        {review?.reply && <div className="review-reply">
          <div className="review-reply-header">
            <div className="review-reply-header__avatar">
              <img src="/images/reply-logo.png" alt="" />
            </div>
            <div className="review-reply-header__name">rockets.coffee</div>
            <div className="review-reply-header__date">{new Date(review.reply.created_at).toLocaleDateString('ru-RU')}</div>
          </div>
          {review.reply.text}
        </div>}
      </div>
    </div>
  )
}

export default ProfileReview