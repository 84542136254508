import { memo } from 'react'
import { PAYMENT_TYPES, paymentTypes } from '../../../utils/enums'
import CartFormFieldRadio from './CartFormFieldRadio'

const CartFormPaymentType = ({ paymentType, onChange }) => {
  

  return (
    <>
      {paymentTypes.map((type, key) => {
        return <CartFormFieldRadio
          key={key}
          active={paymentType == type.type}
          label={type.label}
          info={type.type == PAYMENT_TYPES.CARD && <div className="card-icons"><img src="/images/icons/card-visa.svg" alt="" /><img src="/images/icons/card-master.svg" alt="" /><img src="/images/icons/card-mir.svg" alt="" /></div>}
          prenote={type.type == PAYMENT_TYPES.WIRE && 'Для юридических лиц'}
          onClick={() => onChange(type.type)}
        />
      })}
    </>
  )
}

export default memo(CartFormPaymentType)