import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FormFieldSearch from "./Form/FormFieldSearch"

const SearchForm = ({ isSearchOpen, setIsSearchOpen }) => {
  const navigate = useNavigate()

  const [query, setQuery] = useState('')
  const handleSubmitSearch = (e) => {
    e.preventDefault()
    setIsSearchOpen(false)
    if (query) {
      navigate(`/catalog/search?q=${encodeURIComponent(query)}`)
    } else {
      navigate(`/catalog/coffee`)
    }
  }

  return (
    <div className={`search ${isSearchOpen ? 'active' : ''}`}>
      <form onSubmit={handleSubmitSearch}>
        <FormFieldSearch
          value={query}
          onChange={(value) => setQuery(value)}
          placeholder="Поиск товаров..."
          onClose={() => setIsSearchOpen(false)}
        />
      </form>
    </div>
  )
}

export default SearchForm