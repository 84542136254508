import { useState } from 'react'

const MenuLinkDropdown = ({ arrow = true, label, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="menu-link menu-link__dropdown-container">
      <div className={`menu-link__dropdown-label ${!arrow ? 'hide-xs hide-sm hide-md' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <div>{label}</div>
        {arrow && <img src="/images/utils/arrow-down.svg" className="menu-link__arrow" />}
      </div>
      <div className={`menu-link__dropdown ${!arrow ? 'menu-link__dropdown--mobile-show' : ''} ${isOpen ? 'menu-link__dropdown--mobile-open' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default MenuLinkDropdown