import { useState, memo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCartItems,
  selectCartItemsTotalSum,
} from '../../../features/cartItems/cartItemsSlice'
import { checkCoupon } from '../../../utils/api'

const CartFormFieldCoupon = ({ value, checked, onChange, placeholder, save }) => {
  const cartItems = useSelector(selectCartItems)
  const cartItemsTotalSum = useSelector(selectCartItemsTotalSum)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const check = async () => {
    if (!value || loading) return

    const code = value
    setLoading(true)
    setError(false)
    save(null, 0)
    const data = await checkCoupon(code, cartItems, cartItemsTotalSum)
    if (!data.error && data?.discount > 0) {
      save(code, data.discount)
    } else {
      save(null, 0)
      setError(data.error)
    }
    setLoading(false)
  }

  return (
    <div>
      <div className="cart-form-field-wrapper">
        <div className={`cart-form-field-label ${value?.length ? 'cart-form-field-label--active' : ''}`}>
          {placeholder}
        </div>
        <input
          value={value ? value : ''}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          type="text"
          className={`
            cart-form-field-wrapper-input
            ${error !== false ? ' field-invalid' : ''}
            ${value && value === checked ? ' field-valid' : ''}
          `}
          maxLength={256}
        />
        <div className="cart-form-field-arrow" onClick={check}>
          {loading ? <img src="/images/loading.svg" alt="" /> : <img src="/images/utils/arrow-big.svg" alt="" />}
        </div>
      </div>
      {error && <div className="form-error-note">{error}</div>}
    </div>
  )
}

export default memo(CartFormFieldCoupon)