import { useGetUserQuery } from '../../features/backend/backendApiSlice'
import ProfileOrder from './ProfileOrder'

const ProfileOrders = ({ active }) => {
  const { data: user } = useGetUserQuery()

  console.log(user)

  return (
    <div className={`${!active ? 'display-none' : ''}`}>
      <div className="heading-h2">История заказов</div>
      <div className="profile-orders">
        <div className="profile-orders-header">
          <div className="profile-order-summary">
            <div className="profile-order-summary__data">
              <div>Номер</div>
              <div>Дата</div>
              <div>Скидка</div>
              <div>Сумма</div>
              <div>Статус</div>
            </div>
            <div className="profile-order-summary__expand"></div>
          </div>
        </div>
        {user.customer?.orders?.length > 0 ? user.customer?.orders?.map((order, index) => (
          <ProfileOrder
            key={index}
            order={order}
          />
        )) : 'Пока нет заказов'}
      </div>
    </div>
  )
}

export default ProfileOrders