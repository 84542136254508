import { useYMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import CoffeeShopPlacemark from './CoffeeShopPlacemark'

const CoffeeShopsMap = ({ coffeeShops, activeTab }) => {
  const ymaps = useYMaps(['templateLayoutFactory'])

  if (!ymaps || !ymaps.templateLayoutFactory) {
    return null
  }

  return (
    <Map defaultState={{center: [55.75, 37.57], zoom: 9}} width="100%" height="100%">
      {coffeeShops
        .filter(shop => activeTab ? shop.tags.includes(activeTab) : true)
        // .map((shop, index) => <Placemark key={index} geometry={[ shop.location.latitude, shop.location.longitude ]} />)}
        .map((shop, index) => <CoffeeShopPlacemark
          key={index}
          ymaps={ymaps}
          latitude={shop.location.latitude}
          longitude={shop.location.longitude}
          name={shop.name}
          description={shop.description}
        />)}
    </Map>
  )
}

export default CoffeeShopsMap