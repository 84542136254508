import { Placemark } from "@pbe/react-yandex-maps"

const MapPlacemark = ({ ymaps, onSelect, latitude, longitude, name = '', address = '', workTime = '', addressFull = '', destinationStationId = '' }) => {
  return (
    <Placemark
      geometry={[
        latitude,
        longitude,
      ]}
      properties={{
        latitude: latitude,
        longitude: longitude,
        name: name,
        address: address,
        workTime: workTime,
        addressFull: addressFull,
        destinationStationId: destinationStationId,
      }}
      options={{
        hasBalloon: true,
        hideIconOnBalloonOpen: false,
        iconLayout: ymaps.templateLayoutFactory.createClass(
          '<div class="yandex-map-placemark"></div>'
        ),
        iconShape: {
          type: 'Rectangle',
          coordinates: [[0, 0], [19, 19]]
        },
        balloonLayout: ymaps.templateLayoutFactory.createClass(
          '<div class="yandex-map-balloon">$[[options.contentLayout observeSize minWidth=230 maxWidth=230]]</div>',
          {
            build: function () {
              this.constructor.superclass.build.call(this);
              this._$element = this.getParentElement().querySelector('.yandex-map-balloon');
              this.applyElementOffset();
              this._$element.querySelector('.yandex-map-balloon-link').addEventListener('click', this.selectLinkClick.bind(this));
              this._$element.querySelector('.yandex-map-balloon-close').addEventListener('click', this.close.bind(this));
            },
            selectLinkClick: function () {
              onSelect(this._data.properties._data)
            },
            clear: function () {
              this.constructor.superclass.clear.call(this);
            },
            onSublayoutSizeChange: function () {
              this.applyElementOffset();
            },
            applyElementOffset: function () {
              this._$element.style.left = `${-15}px`;
              this._$element.style.top = `${-(this._$element.offsetHeight + 10)}px`;
            },
            close: function () {
              this.events.fire('userclose');
            }
          }
        ),
        balloonContentLayout: ymaps.templateLayoutFactory.createClass(
          '<div class="yandex-map-balloon-header">' +
          '<div class="yandex-map-balloon-heading">{{ properties.name }}</div>' +
          '<div class="yandex-map-balloon-close"><img src="/images/icons/close.png" alt=""></div>' + 
          '</div>' +
          '<div class="yandex-map-balloon-line">{{ properties.address }}</div>' +
          '<div class="yandex-map-balloon-line">{{ properties.workTime }}</div>' +
          '<a class="yandex-map-balloon-link">Выбрать пункт самовывоза</a>'
        ),
        balloonPanelMaxMapArea: 1,
      }}
      modules={['geoObject.addon.balloon']}
    />
  )
}

export default MapPlacemark