import { memo } from 'react'

const FormFieldSearch = ({ value, onChange, placeholder, onClose, className = '' }) => {
  return (
    <div className={`cart-form-field-wrapper ${className}`}>
      <div className="search-icon-left"><img src="/images/icons/search.png" alt="" /></div>
      <input
        value={value ? value : ''}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        type="text"
        className={`cart-form-field-wrapper-input search-field`}
        maxLength={256}
      />
      <div className="search-icon-right" onClick={onClose}><img src="/images/icons/close-gray.svg" alt="" /></div>
    </div>
  )
}

export default memo(FormFieldSearch)