import { useState, useEffect, useRef } from 'react';

const Modal = ({ children, heading, isOpen, onClose, className = '' }) => {
  const [isLong, setIsLong] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      const modalHeight = modalRef.current.offsetHeight;
      const viewportHeight = window.innerHeight;
      if (modalHeight > viewportHeight) {
        setIsLong(true);
      } else {
        setIsLong(false);
      }
    }
  }, [isOpen]);

  return (
    <div className={`modal-wrapper ${isOpen ? 'active' : ''} ${className} ${isLong ? 'long' : ''}`}>
      <div className="modal" ref={modalRef}>
        <div className="modal-close" onClick={onClose}><img src="/images/icons/close.png" alt="" /></div>
        <div className="modal-heading">{heading}</div>
        {children}
      </div>
    </div>
  )
}

export default Modal