import { contactsMapPoints } from '../data/data'
import { useYMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import CoffeeShopPlacemark from './CoffeeShopPlacemark'

const ContactsMap = ({ activeTab }) => {
  const ymaps = useYMaps(['templateLayoutFactory'])

  if (!ymaps || !ymaps.templateLayoutFactory) {
    return null
  }

  const mapPoint = contactsMapPoints.find(point => point.key == activeTab)

  return (
    <Map state={{center: [ mapPoint.latitude, mapPoint.longitude ], zoom: activeTab == 1 ? 13 : 12}} width="100%" height="100%">
      <Placemark
        geometry={[ mapPoint.latitude, mapPoint.longitude ]}
        options={{
          iconColor: '#1D71B8',
        }}
      />
      {/* <CoffeeShopPlacemark
        ymaps={ymaps}
        latitude={mapPoint.latitude}
        longitude={mapPoint.longitude}
        name="Name"
        description={["Description"]}
      /> */}
    </Map>
  )
}

export default ContactsMap