import { SLUG_ATTR_BITTERNESS, SLUG_ATTR_ACIDITY, SLUG_ATTR_SWEETNESS } from '../utils/enums'

const ProductSpecs = ({ product, className = '' }) => {
  const bitterness = product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_BITTERNESS) ? parseInt(product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_BITTERNESS).options[0].name) : false
  const sweetness = product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_SWEETNESS) ? parseInt(product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_SWEETNESS).options[0].name) : false
  const acidity = product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_ACIDITY) ? parseInt(product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_ACIDITY).options[0].name) : false

  if (bitterness === false && sweetness === false && acidity === false) {
    return null
  }

  return (
    <div className={`catalog-product-specs ${className}`}>
      <div className="catalog-product-spec">
        <div className="catalog-product-spec-label">Горечь</div>
        <div className="catalog-product-spec-indicators">
          {[...Array(5)].map((x, i) =>
            <div key={i} className={`catalog-product-spec-indicator ${i < bitterness ? 'active' : ''}`} />
          )}
        </div>
      </div>
      <div className="catalog-product-spec">
        <div className="catalog-product-spec-label">Сладость</div>
        <div className="catalog-product-spec-indicators">
          {[...Array(5)].map((x, i) =>
            <div key={i} className={`catalog-product-spec-indicator ${i < sweetness ? 'active' : ''}`} />
          )}
        </div>
      </div>
      <div className="catalog-product-spec">
        <div className="catalog-product-spec-label">Кислотность</div>
        <div className="catalog-product-spec-indicators">
          {[...Array(5)].map((x, i) =>
            <div key={i} className={`catalog-product-spec-indicator ${i < acidity ? 'active' : ''}`} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductSpecs