import { SLUG_FILTER_PERKS } from '../../utils/enums'
import CatalogFiltersPellet from './CatalogFiltersPellet'

const CatalogFiltersFull = ({ category, filters }) => {
  if (filters.length === 0) {
    return null
  }

  return (
    <>
      {filters.map((filter, key) => (
        <div className="catalog-filters-section">
          <div className="catalog-filters-section-title">{filter.attribute.name}</div>
          <div className="catalog-filters-pellets-wrapper">
            {filter.options.map((option, key) => (
              <CatalogFiltersPellet key={key} category={category} option={option} />
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

export default CatalogFiltersFull