const CartFormFieldRadio = ({ label, active = false, info, price, note, prenote, onClick }) => {
  return (
    <div onClick={onClick}>
      {prenote && <div className="cart-form-field-radio-prenote">{prenote}</div>}
      <div className="cart-form-field-wrapper">
        <div className="cart-form-field-wrapper-radio">
          <div>
            <div className="cart-form-field-radio-tick">
              {active && <div className="cart-form-field-radio-tick-active"></div>}
            </div>
            <div className="cart-form-field-radio-label">{label}</div>
            {price && <div className="cart-form-field-radio-price">{price}</div>}
          </div>
          {info && <div className="cart-form-field-radio-info">{info}</div>}
        </div>
      </div>
      {note && <div className="cart-form-field-radio-note">{note}</div>}
    </div>
  )
}

export default CartFormFieldRadio