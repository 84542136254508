import { tastes } from '../data/data'
import Taste from './Taste'
import { Link } from 'react-router-dom'

const Review = ({ review }) => {
  return (
    <div className="review">
      <div className="review-header">
        <div>
          <div className="review-meta">
            <div className="review-meta__avatar">
              {review.customer.avatar ? <img src={import.meta.env.VITE_BACKEND_URL + review.customer.avatar} alt="" /> : <img src="/images/utils/avatar-empty.png" alt="" />}
            </div>
            <div className="review-meta__data">
              <div className="review-meta__name">{review.customer.first_name ?? review.customer.email}</div>
              <div className="review-meta__date">{new Date(review.created_at).toLocaleDateString('ru-RU')}</div>
            </div>
          </div>
          {review.review_category
            ? (!review.product
              ? <div className="review-meta__type">{review.review_category.name}</div>
              : <Link to={`/products/${review.product.slug}`} className="review-meta__type">{review.product.name}</Link>)
            : null
          }
        </div>
        <div className="review-rating">
          {[1,2,3,4,5].map((rating) => (
            <img
              key={rating}
              src={`/images/utils/star-${review.rating >= rating ? 'blue' : 'gray'}.svg`}
            />
          ))}
        </div>
      </div>
      <div className="review-body">
        {review.tastes && <div className="review-tastes-selected-summary mb-20">
          {review.tastes.map((taste_id) => (
            <Taste
              key={taste_id}
              name={tastes.find(taste => taste.id === taste_id).name}
              color={tastes.find(taste => taste.id === taste_id).color}
            />
          ))}
        </div>}

        {review.text}
        
        {review?.reply && <div className="review-reply">
          <div className="review-reply-header">
            <div className="review-reply-header__avatar">
              <img src="/images/reply-logo.png" alt="" />
            </div>
            <div className="review-reply-header__name">rockets.coffee</div>
            <div className="review-reply-header__date">{new Date(review.reply.created_at).toLocaleDateString('ru-RU')}</div>
          </div>
          {review.reply.text}
        </div>}
      </div>
    </div>
  )
}

export default Review