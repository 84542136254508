const pendingRequests = {}

const request = async (handle, url, data = {}) => {
  const previousController = pendingRequests[handle]

  if (previousController) {
    previousController.abort()
  }
    
  const controller = new AbortController()
  pendingRequests[handle] = controller

  const response = await fetch(url, {
    // method: form.method,
    // body: new FormData(form),
    ...data,
    signal: controller.signal,
  })

  delete pendingRequests.handle

  return response
}

const requestWithToken = async (handle, url, data = {}) => {
  const token = localStorage.getItem('token')

  if (!token) {
    return false
  }

  const response = await request(handle, url, {
    ...data,
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest',
      ...data.headers,
    },
  })

  if (response.status === 401) {
    localStorage.removeItem('token')
    return false
  }

  return response
}

export const checkCertificate = async (code) => {
  const response = await request('checkCertificate', `${import.meta.env.VITE_BACKEND_URL}api/certificates/check?code=${code}`)
  const data = await response.json()

  return data
}

export const checkCoupon = async (code, cartItems, cartTotalSum) => {
  const response = await requestWithToken('checkCoupon', `${import.meta.env.VITE_BACKEND_URL}api/coupons/check`, {
    method: 'POST',
    body: JSON.stringify({
      code: code,
      cartItems: cartItems,
      cartTotalSum: cartTotalSum,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data = await response.json()

  return data
}

export const getDeliveryPriceDalli = async (address, weight) => {
  const handle = 'getDeliveryPriceDalli' + address + weight

  const response = await request(handle, `${import.meta.env.VITE_BACKEND_URL}api/utils/getDeliveryPriceDalli?address=${address}&weight=${weight}`)
  const data = await response.json()

  return data
}

export const getDeliveryPriceCdek = async (tariff_code, country_iso_code, city, address, postal_code, weight) => {
  const handle = 'getDeliveryPriceCdek' + tariff_code + country_iso_code + city + address + postal_code + weight

  const response = await request(handle, `${import.meta.env.VITE_BACKEND_URL}api/utils/getDeliveryPriceCdek?tariff_code=${tariff_code}&country_iso_code=${country_iso_code}&city=${city}&address=${address}&postal_code=${postal_code}&weight=${weight}`)
  const data = await response.json()

  return data
}

export const getDeliveryPriceYandex = async (tariff, address, destination_station_id, weight) => {
  const handle = 'getDeliveryPriceYandex' + tariff + address + destination_station_id + weight

  const response = await request(handle, `${import.meta.env.VITE_BACKEND_URL}api/utils/getDeliveryPriceYandex?tariff=${tariff}&address=${address}&destination_station_id=${destination_station_id}&weight=${weight}`)
  const data = await response.json()

  return data
}

export const getDeliveryPriceRussianPost = async (fias_id, weight) => {
  const handle = 'getDeliveryPriceRussianPost' + fias_id + weight

  const response = await request(handle, `${import.meta.env.VITE_BACKEND_URL}api/utils/getDeliveryPriceRussianPost?fias_id=${fias_id}&weight=${weight}`)
  const data = await response.json()

  return data
}

export const storeOrder = async (order) => {
  const token = localStorage.getItem('token')
  let requestType
  if (!token) {
    requestType = request
  } else {
    requestType = requestWithToken
  }

  const response = await requestType('storeOrder', `${import.meta.env.VITE_BACKEND_URL}api/orders/store`, {
    method: 'POST',
    body: JSON.stringify(order),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data = await response.json()

  return data
}

export const checkPhone = async (phone, action = 'register') => {
  const response = await request('checkPhone', `${import.meta.env.VITE_BACKEND_URL}api/checkPhone`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone: phone,
      action: action,
    }),
  })
  const data = await response.json()

  return data
}

export const checkCode = async (phone, phoneCode, phoneNumber, code, firstName, mCRMPromocode = '') => {
  const response = await request('checkCode', `${import.meta.env.VITE_BACKEND_URL}api/checkCode`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone: phone,
      phone_code: phoneCode,
      phone_number: phoneNumber,
      first_name: firstName,
      code: code,
      mcrm_promocode: mCRMPromocode,
    }),
  })
  const data = await response.json()

  return data
}

export const login = async (phone, password) => {
  const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}api/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone: phone,
      password: password,
    }),
  })
  const data = await response.json()

  return data
}

export const logout = async () => {
  const response = await requestWithToken('logout', `${import.meta.env.VITE_BACKEND_URL}api/logout`, {
    method: 'POST',
  })
}

export const updateCustomer = async (payload) => {
  const formData = new FormData()

  formData.append('_method', 'PATCH')

  for (const key in payload) {
    formData.append(key, payload[key])
  }

  formData.append('token_mcrm', localStorage.getItem('token_mcrm'))

  const response = await requestWithToken('updateCustomer', `${import.meta.env.VITE_BACKEND_URL}api/customers`, {
    method: 'POST',
    body: formData,
  })

  if (response === false) {
    return false
  }

  const data = await response.json()

  return data
}

export const storeCustomerAddress = async (payload) => {
  const response = await requestWithToken('storeCustomerAddress', `${import.meta.env.VITE_BACKEND_URL}api/customers/addresses`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()

  return data
}

export const updateCustomerAddress = async (payload) => {
  const response = await requestWithToken('updateCustomerAddress', `${import.meta.env.VITE_BACKEND_URL}api/customers/addresses/${payload.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()

  return data
}

export const setAsDefaultCustomerAddress = async (id) => {
  const response = await requestWithToken('setAsDefaultCustomerAddress', `${import.meta.env.VITE_BACKEND_URL}api/customers/addresses/${id}/setAsDefault`, {
    method: 'PATCH',
  })
  const data = await response.json()

  return data
}

export const deleteCustomerAddress = async (id) => {
  const response = await requestWithToken('deleteCustomerAddress', `${import.meta.env.VITE_BACKEND_URL}api/customers/addresses/${id}`, {
    method: 'DELETE',
  })
  const data = await response.json()

  return data
}

export const addProductToFavorites = async (product_id) => {
  const response = await requestWithToken('addProductToFavorites', `${import.meta.env.VITE_BACKEND_URL}api/customers/products/${product_id}`, {
    method: 'POST',
  })
  const data = await response.json()

  return data
}

export const removeProductFromFavorites = async (product_id) => {
  const response = await requestWithToken('removeProductFromFavorites', `${import.meta.env.VITE_BACKEND_URL}api/customers/products/${product_id}`, {
    method: 'DELETE',
  })
  const data = await response.json()

  return data
}

export const storeReview = async (payload) => {
  const response = await requestWithToken('storeReview', `${import.meta.env.VITE_BACKEND_URL}api/customers/reviews`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()

  return data
}

export const mCRMRecoverPassword = async (phone) => {
  const response = await request('mCRMRecoverPassword', `${import.meta.env.VITE_BACKEND_URL}api/mCRMRecoverPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone: phone,
    }),
  })
  const data = await response.json()

  return data
}

export const mCRMAuth = async (phone, password) => {
  const response = await request('mCRMAuth', `${import.meta.env.VITE_BACKEND_URL}api/mCRMAuth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone: phone,
      password: password,
    }),
  })
  const data = await response.json()

  return data
}

export const sendContactsForm = async (payload) => {
  const response = await request('sendContactsForm', `https://ingeni.app/15/sites/martios.store/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()

  return data
}

export const logFrontendError = async (error) => {
  const response = await request('logFrontendError', `${import.meta.env.VITE_BACKEND_URL}api/utils/logFrontendError`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(error),
  })
  const data = await response.json()

  return data
}