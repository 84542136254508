import { useState } from 'react'
import { BREWING_METHODS, brewingMethods, SLUG_ATTR_BREWING_METHOD } from '../utils/enums'

const BrewingMethods = ({ product }) => {
  const brewing_methods = product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_BREWING_METHOD) ? product.attributes.find(elem => elem.attribute.slug === SLUG_ATTR_BREWING_METHOD).options : false

  if (brewing_methods === false) {
    return null
  }

  const [activeTab, setActiveTab] = useState(brewingMethods.find(elem => elem.slug === brewing_methods[0].slug)?.type)

  const handleMouseDown = (e) => {
    e.preventDefault()
    // const target = e.target
    const target = document.querySelector('.brewing-method-wrapper')
    const scrollWidth = target.scrollWidth
    const startX = e.pageX - target.offsetLeft
    const scrollLeft = target.scrollLeft

    target.style.cursor = 'grabbing'
    target.style.scrollBehavior = 'unset'

    const mouseMove = (e) => {
      const x = e.pageX - target.offsetLeft
      const walk = (x - startX) * 2
      target.scrollLeft = scrollLeft - walk
    }

    const mouseUp = () => {
      target.style.cursor = 'grab'
      target.style.scrollBehavior = 'smooth'
      document.removeEventListener('mousemove', mouseMove)
      document.removeEventListener('mouseup', mouseUp)
    }

    document.addEventListener('mousemove', mouseMove)
    document.addEventListener('mouseup', mouseUp)
  }

  return (
    <div className="tabs-container">
      <div className="tabs">
        <div className="product-heading brewing-methods-heading">Как правильно<br />готовить кофе</div>
        {brewingMethods.map((method, index) => {
          if (!brewing_methods.find(elem => elem.slug === method.slug)) {
            return null
          }
          return <div key={index} className={`tab-link ${activeTab == method.type ? 'active' : ''}`} onClick={() => setActiveTab(method.type)}>{method.label}</div>
        })}
      </div>
      <div className="tabs-content brewing-method-wrapper" onMouseDown={handleMouseDown}>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.DRIP ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip/step-1.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Подготовьте все необходимое (чайник, высокая кружка или сервер, дрип-пакет)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip/step-2.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Достаньте дрип-пакет и встряхните его</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip/step-3.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Разместите дрип-пакет на кружку</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip/step-4.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Залейте воду до края дрип-пакета и дождитесь полного протекания, повторите это действия еще два раза</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip/step-5.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Перемешайте напиток и наслаждайтесь!</div>
          </div>
        </div>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.IMMERSION ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/immersion/step-1.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Сложите бумажный фильтр, вставьте в воронку, смочите горячей водой</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/immersion/step-2.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Засыпьте 20г кофе среднего помола</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/immersion/step-3.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Интенсивным единоразовым вливанием залейте 300мл воды</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/immersion/step-4.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Подождите 4 минуты</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/immersion/step-5.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Сломайте «кофейную шапку», вращая воронку вокруг своей оси по часовой и против часовой стрелки</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/immersion/step-6.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 6</div>
            <div className="brewing-method-step__text">Установите воронку на сервер и дождитесь полного протекания напитка</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/immersion/step-7.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 7</div>
            <div className="brewing-method-step__text">Ваш напиток готов, наслаждайтесь!</div>
          </div>
        </div>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.AEROPRESS ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-1.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Подготовьте необходимые аксессуары (вам понадобятся: весы, аэропресс, бумажный фильтр, горячая вода и кофе)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-2.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Установите бумажный фильтр</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-3.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Смочите бумажный фильтр водой</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-4.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Прогрейте аэропресс горячей водой</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-5.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Засыпьте необходимое количество кофе, в нашем рецепте мы используем 16 г мелко-среднего помола (мельче сахара)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-6.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 6</div>
            <div className="brewing-method-step__text">Залейте 220 мл воды температурой 90-92С одним непрерывным вливанием</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-7.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 7</div>
            <div className="brewing-method-step__text">Размешайте ложкой до 1:00 непрерывно</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-8.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 8</div>
            <div className="brewing-method-step__text">Закрутите крышку со смоченным фильтром</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-9.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 9</div>
            <div className="brewing-method-step__text">Аккуратно переверните конструкцию и начинайте давить на плунжер, его необходимо продавить за 25-30 сек</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/aeropress/step-10.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 10</div>
            <div className="brewing-method-step__text">Ваш напиток готов, наслаждайтесь!</div>
          </div>
        </div>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.FUNNEL ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/funnel/step-1.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Подготовьте все необходимое (вам понадобятся: весы, воронка, бумажный фильтр, горячая вода и кофе)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/funnel/step-2.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Вставьте бумажный фильтр в воронку и смочите его горячей водой</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/funnel/step-3.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Засыпьте необходимое количество кофе, мы рекомендуем использовать 18гр кофе среднего помола (мельче тростникового сахара)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/funnel/step-4.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Начинаем вливать воду. 50 грамм по спирали от центра к краю за 15 сек. В 0:35 влить 50 гр. воды по спирали. В 1:10 влить 100 гр воды по спирали за 20 сек. В 2:20 влить 100 гр. воды по спирали за 20 сек.</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/funnel/step-5.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">После вливания последней порции воды сделать «раоспин» (вращение воронки)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/funnel/step-6.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 6</div>
            <div className="brewing-method-step__text">Общее время заваривания: 3:00 - 3:30 в зависимости от выбранного кофе, размера помола и техники вливания</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/funnel/step-7.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 7</div>
            <div className="brewing-method-step__text">Ваш напиток готов, наслаждайтесь!</div>
          </div>
        </div>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.GEYSER ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/geyser/step-1.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Залейте воду в нижнюю часть устройства до клапана (вода может быть как комнатной температуры, так и теплой, до 50С)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/geyser/step-2.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Используйте соотношение кофе и воды 60-65г на 1 литр, ориентируюсь на объемы воды вашего устройства</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/geyser/step-3.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Поместите сверху конструкции отделение для готового кофе</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/geyser/step-4.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Поставьте гейзер на плиту</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/geyser/step-5.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Через 2-4 минуты снимайте с плиты, ваш напиток готов, наслаждайтесь!</div>
          </div>
        </div>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.DRIP_COFFEE_MAKER ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip-coffee-maker/step-1.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Подготовьте все необходимое (вам понадобятся: весы, бумажный фильтр, вода и кофе)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip-coffee-maker/step-2.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Вставьте и смочите фильтр горячей водой, засыпьте молотый кофе, в зависимости от объема готового напитка, в соотношении 55-65г кофе на 1л воды</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip-coffee-maker/step-3.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Залейте необходимый объем воды в резервуар</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip-coffee-maker/step-4.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Включите кофеварку и дождитесь полного протекания воды (когда прольется первая порция воды – размешайте кофейную гущу)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/drip-coffee-maker/step-5.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Ваш напиток готов, наслаждайтесь!</div>
          </div>
        </div>
        {/* COFFEE MACHINE */}
        <div className={`brewing-method ${activeTab != BREWING_METHODS.TURK ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/turk/step-1.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Насыпьте кофе, в зависимости от объема вашей турки, используя соотношение 1г кофе к 10мл воды</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/turk/step-2.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Залейте холодную воду до сужения горлышка турки</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/turk/step-3.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Размешайте кофе</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/turk/step-4.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Поставьте на плиту до поднятия «шапки», это может занимать от 1,5 до 3 минут в зависимости от объема</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/turk/step-5.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Перелейте кофе вместе с гущей в чашку</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/turk/step-5.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 6</div>
            <div className="brewing-method-step__text">Подождите 3-4 минуты до комфортной температуры, ваш напиток готов, наслаждайтесь!</div>
          </div>
        </div>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.FRENCH_PRESS ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/french-press/step-1.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Прогрейте френч-пресс горячей водой</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/french-press/step-2.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Насыпьте молотый кофе, в зависимости от объема вашего френч-пресса, используя соотношение 60-65 г кофе на 1 л воды</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/french-press/step-3.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Залейте горячей водой (94-95С) единоразовым интенсивным вливанием</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/french-press/step-4.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Подождите 5 минут</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/french-press/step-5.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Сломайте «кофейную шапку» ложкой</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/french-press/step-6.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 6</div>
            <div className="brewing-method-step__text">Подождите 3 минуты, вставьте плунжер, немного опустите и перелейте напиток в чашку</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/french-press/step-7.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 7</div>
            <div className="brewing-method-step__text">Ваш напиток готов, наслаждаетесь!</div>
          </div>
        </div>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.CUP ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/cup/step-1.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Прогрейте чашку горячей водой</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/cup/step-2.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Насыпьте молотый кофе, в зависимости от объема вашей чашки, используя соотношение 60-65г кофе на 1л воды</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/cup/step-3.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Залейте горячей водой (94-95С) единоразовым интенсивным вливанием</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/cup/step-4.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Подождите 4-5 минут</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/cup/step-5.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Сломайте «кофейную шапку» ложкой</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/cup/step-6.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 6</div>
            <div className="brewing-method-step__text">Зачистите чашку от остатков «кофейной шапки», используя две ложки</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/cup/step-7.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 7</div>
            <div className="brewing-method-step__text">Подождите 3 минуты до достижения комфортной температуры</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/cup/step-8.png" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 8</div>
            <div className="brewing-method-step__text">Ваш напиток готов, наслаждайтесь!</div>
          </div>
        </div>
        <div className={`brewing-method ${activeTab != BREWING_METHODS.CAPSULE ? 'display-none' : ''}`}>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/capsule/step-1.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 1</div>
            <div className="brewing-method-step__text">Подготовьте все необходимое для приготовления айс-латте (вам понадобятся: бокал, капсула, молоко, лед и сироп по вкусу)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/capsule/step-2.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 2</div>
            <div className="brewing-method-step__text">Приготовьте капсулу на объеме «эспрессо»</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/capsule/step-3.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 3</div>
            <div className="brewing-method-step__text">Заполните бокал льдом</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/capsule/step-4.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 4</div>
            <div className="brewing-method-step__text">Добавьте сироп по вкусу</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/capsule/step-5.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 5</div>
            <div className="brewing-method-step__text">Налейте молоко в бокал (объем зависит от желаемой крепости напитка)</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/capsule/step-6.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 6</div>
            <div className="brewing-method-step__text">Влейте порцию эспрессо</div>
          </div>
          <div className="brewing-method-step">
            <div className="brewing-method-step__image"><img src="/images/brewing-methods/capsule/step-7.webp" alt="" /></div>
            <div className="brewing-method-step__name">Шаг 7</div>
            <div className="brewing-method-step__text">Перемешайте напиток и наслаждайтесь!</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrewingMethods