import { useState, memo } from 'react'
import { InputMask } from '@react-input/mask'

const FormFieldMasked = ({ mask, value, onChange, placeholder, isInvalid, validateField }) => {
  return (
    <div className="cart-form-field-wrapper">
      <div className="cart-form-field-label cart-form-field-label--active">
        {placeholder}
      </div>
      <InputMask
        mask={mask}
        replacement={{ _: /\d/ }}
        showMask={true}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => validateField ? validateField(e.target.value) : true}
        className={`cart-form-field-wrapper-input ${isInvalid ? 'field-invalid' : ''}`}
      />
    </div>
  )
}

export default memo(FormFieldMasked)