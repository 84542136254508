import { useState } from 'react'
import { Link } from 'react-router-dom'

const FeaturedSlide = ({ slide }) => {
  const [hiddenAttributes, setHiddenAttributes] = useState([])

  const toggleAttribute = (index) => {
    if (hiddenAttributes.includes(index)) {
      setHiddenAttributes(hiddenAttributes.filter((item) => item !== index))
    } else {
      setHiddenAttributes([...hiddenAttributes, index])
    }
  }

  return (
    <div>
      <div
        className="featured-slide"
        style={{
          backgroundImage: `url(${import.meta.env.VITE_BACKEND_URL + slide.cover})`,
          backgroundSize: 'cover',
        }}
      >
        <div>
          
        </div>
        <div className="featured-slide-meta">
          <div className="featured-slide-info-desktop">
            <div className="featured-slide-info__label">{slide.label}</div>
            <div className="featured-slide-info__heading">{slide.heading}</div>
            <div className="featured-slide-info__product">{slide.product ? slide.product.name : slide.category.name}</div>
            <div className="featured-slide-info__price" dangerouslySetInnerHTML={{ __html: slide.price }}></div>
            <Link to={slide.product ? `/products/${slide.product.slug}` : `/catalog/${slide.category.slug}`} className="button-big-primary-wide">Купить</Link>
          </div>
        </div>
      </div>
      <div className="featured-slide-info-mobile">
        <div>
          <div className="featured-slide-info__label">{slide.label}</div>
          <div className="featured-slide-info__heading">{slide.heading}</div>
          <div className="featured-slide-info__product">{slide.product ? slide.product.name : slide.category.name}</div>
          <div className="featured-slide-info__price" dangerouslySetInnerHTML={{ __html: slide.price }}></div>
          <Link to={slide.product ? `/products/${slide.product.slug}` : `/catalog/${slide.category.slug}`} className="button-big-primary-wide">Купить</Link>
        </div>
      </div>
    </div>
  )
}

export default FeaturedSlide