const ProfileOrderItem = ({ variation }) => {
  const getProfileOrderItemImage = (variation) => {
    if (variation?.images?.length > 0 && variation?.images[0]?.path) {
      return import.meta.env.VITE_BACKEND_URL + variation?.images[0]?.path
    }
    if (variation.product && variation.product.variations?.length > 0 && variation.product.variations[0].images?.length > 0 && variation.product.variations[0]?.images[0]?.path) {
      return import.meta.env.VITE_BACKEND_URL + product.variations[0]?.images[0]?.path
    }
    return // TODO return no image?
  }

  return (
    <div className="profile-order-product">
      <div className="profile-order-product-info">
        <div className="profile-order-product-info__image">
          <img src={getProfileOrderItemImage(variation)} alt="" />
        </div>
        <div className="profile-order-product-info__props">
          <div className="profile-order-product-info__name">{variation.product.name}</div>
          <div className="profile-order-product-options">
            {variation.options.map((option, index) => (
              <div key={index} className="profile-order-product-option">
                {option.name}
              </div>
            ))}
            <div className="profile-order-product-option">
              <div>{variation.pivot.qty} шт.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-order-product-price">{variation.pivot.price / 100 * variation.pivot.qty} <span className="commissioner-400">₽</span></div>
    </div>
  )
}

export default ProfileOrderItem