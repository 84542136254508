const ModalFull = ({ children, heading, isOpen, onClose }) => {
  return (
    <div className={`modal-wrapper modal-wrapper-full ${isOpen ? 'active' : ''}`}>
      <div className="modal">
        <div className="modal-close" onClick={onClose}><img src="/images/icons/close.png" alt="" /></div>
        <div className="modal-heading">{heading}</div>
        {children}
      </div>
    </div>
  )
}

export default ModalFull