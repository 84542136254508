import { useState, useCallback } from 'react'

const useForm = (
  initialState = {},
  validationRules = {},
  submit = async (fields, setError) => console.log('submit', fields)
) => {
  const [fields, setFields] = useState(initialState)
  const [invalidFields, setInvalidFields] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const setField = (name) => (value) => setFields((prevState) => ({ ...prevState, [name]: value }))

  const setFieldMemoized = (name) => useCallback((value) => setFields((prevState) => ({ ...prevState, [name]: value })), [])

  const setFieldMultiple = (data) => setFields((prevState) => ({ ...prevState, ...data }))

  const setFieldMultipleMemoized = useCallback((data) => setFields((prevState) => ({ ...prevState, ...data })), [])

  const setFieldIsInvalid = (name, isInvalid) => setInvalidFields((prevState) => ({...prevState, [name]: isInvalid}))

  const validateFieldMemoized = (name) => useCallback((value) => {
    setFieldIsInvalid(name, validationRules[name] && validationRules[name](value))
  })

  const validateForm = () => {
    let invalidFields = {}

    Object.keys(validationRules).forEach(key => {
      if (validationRules[key] && validationRules[key](fields[key] ?? '')) {
        invalidFields[key] = true
      }
    })

    setInvalidFields(invalidFields)

    return invalidFields
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    
    if (isSubmitting === true) return

    const invalidFields = validateForm()
    if (Object.keys(invalidFields).length > 0) {
      return
    }
    
    setError(null)
    setIsSubmitting(true)

    await submit(fields, setError)

    setIsSubmitting(false)
  }

  return {
    fields,
    setFields,
    invalidFields,
    validateFieldMemoized,
    setField,
    setFieldMemoized,
    setFieldMultiple,
    setFieldMultipleMemoized,
    handleSubmit,
    isSubmitting,
    error,
    setError,
  }
}

export default useForm