import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useGetUserQuery } from '../features/backend/backendApiSlice'

const BannerTopline = () => {
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery()

  const [toplineSeen, setToplineSeen] = useState(false)

  const handleCloseTopline = () => {
    localStorage.setItem('toplineSeen', '1')
    setToplineSeen(true)
    setTimeout(() => {
      onResize()
    }, 100)
  }

  const onResize = () => {
    const headerSpacer = document.querySelector('.header-spacer')
    if (headerSpacer) {
      headerSpacer.style.height = document.querySelector('.header').offsetHeight + 'px'
    }
  }

  useEffect(() => {
    onResize()

    window.removeEventListener('resize', onResize)
    window.addEventListener('resize', onResize, { passive: true })
    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      onResize()
    }, 100)
  }, [user, isLoadingUser])

  const show = ((!isLoadingUser && !user?.id) && toplineSeen !== true && localStorage.getItem('toplineSeen') !== '1')

  if (!show) {
    return null
  }

  return (
    <div className="topline">
      <Link to="/register">Зарегистрируйтесь</Link> и получите скидку 10% на кофе промокоду <b>new</b>
      <div
        className="topline-close"
        onClick={() => handleCloseTopline()}
      >
        <img src="/images/icons/close-white.svg" alt="" />
      </div>
    </div>
  )
}

export default BannerTopline