import { useState } from 'react'
import { useGetUserQuery } from '../../features/backend/backendApiSlice'
import useForm from '../../hooks/useForm'
import ProfileAddress from './ProfileAddress'
import FormFieldText from '../Form/FormFieldText'
import FormFieldAddress from '../Form/FormFieldAddress'
import { storeCustomerAddress, updateCustomerAddress } from '../../utils/api'

const ProfileAddresses = ({ active }) => {
  const { data: user, refetch } = useGetUserQuery()

  const [isFormVisible, setIsFormVisible] = useState(false)

  const {
    fields,
    setFields,
    invalidFields,
    validateFieldMemoized,
    setFieldMemoized,
    setFieldMultipleMemoized,
    handleSubmit,
    isSubmitting,
  } = useForm({}, {
    name: (value) => value.length < 1,
    address: (value) => value.length < 1,
  }, async (fields) => {
    fields.is_default = user.customer?.addresses?.length > 0 ? false : true
    const response = (fields?.id) ? await updateCustomerAddress(fields) : await storeCustomerAddress(fields)
    await refetch()
    setIsFormVisible(false)
  })

  const handleCreateAddress = () => {
    setFields({
      is_default: false,
    })
    setIsFormVisible(true)
  }

  const handleEditAddress = (address) => {
    setFieldMultipleMemoized(address)
    setIsFormVisible(true)
  }

  return (
    <div className={`${!active ? 'display-none' : ''}`}>
      <div className="heading-h2">Адреса</div>
      <div className="profile-addresses mb-40">
        {user.customer?.addresses?.length > 0 ? user.customer?.addresses?.map((address, index) => (
          <ProfileAddress
            key={index}
            address={address}
            handleEditAddress={handleEditAddress}
            refetch={refetch}
          />
        )) : 'Пока нет адресов'}
      </div>
      <div className="link-action mb-40" onClick={() => handleCreateAddress()}>Добавить новый</div>
      <div className={`profile-data-form ${!isFormVisible ? 'display-none' : ''}`}>
        <div className="heading-h2">Адреса</div>
        <form onSubmit={handleSubmit}>
          <div className="form-fields-container-1col mb-20">
            <FormFieldText
              value={fields.name ?? ''}
              isInvalid={invalidFields.name}
              placeholder={'Название'}
              onChange={setFieldMemoized('name')}
              validateField={validateFieldMemoized('name')}
            />
            <FormFieldAddress
              address={fields?.address ?? ''}
              onChange={setFieldMultipleMemoized}
              isInvalid={invalidFields.address}
              validateField={validateFieldMemoized('address')}
            />
          </div>
          <div className="form-fields-container-2col mb-20">
            <FormFieldText
              value={fields?.entrance ?? ''}
              onChange={setFieldMemoized('entrance')}
              placeholder={'Подъезд'}
              isInvalid={false}
            />
            <FormFieldText
              value={fields?.floor ?? ''}
              onChange={setFieldMemoized('floor')}
              placeholder={'Этаж'}
              isInvalid={false}
            />
            <FormFieldText
              value={fields?.apartment ?? ''}
              onChange={setFieldMemoized('apartment')}
              placeholder={'Квартира'}
              isInvalid={false}
            />
            <FormFieldText
              value={fields?.intercom ?? ''}
              onChange={setFieldMemoized('intercom')}
              placeholder={'Домофон'}
              isInvalid={false}
            />
          </div>
          <div className="form-buttons">
            <button type="submit" className={`button-big-primary-wide ${isSubmitting ? 'disabled' : ''}`}>
              {isSubmitting ? 'Сохраняем...' : 'Сохранить адрес'}
            </button>
            <div className="form-button-dismiss" onClick={() => setIsFormVisible(false)}>Отменить</div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProfileAddresses