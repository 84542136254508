import { useState, memo } from 'react'

const FormFieldArrow = ({ value, onChange, placeholder, isInvalid, validateField, action }) => {
  const [loading, setLoading] = useState(false)

  const submit = async (e) => {
    if (loading) return

    setLoading(true)
    const data = await action(e)
    setLoading(false)
  }

  return (
    <div className="cart-form-field-wrapper">
      <div className={`cart-form-field-label ${value?.length ? 'cart-form-field-label--active' : ''}`}>
        {placeholder}
      </div>
      <input
        value={value ? value : ''}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => validateField ? validateField(e.target.value) : true}
        placeholder={placeholder}
        type="text"
        className={`
          cart-form-field-wrapper-input
          ${isInvalid === true ? ' field-invalid' : ''}
        `}
        maxLength={256}
      />
      <div className="cart-form-field-arrow" onClick={submit}>
        {loading ? <img src="/images/loading.svg" alt="" /> : <img src="/images/utils/arrow-big.svg" alt="" />}
      </div>
    </div>
  )
}

export default memo(FormFieldArrow)