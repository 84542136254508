import { Link } from "react-router-dom"

const MenuLink = ({ to, label, className, target = null }) => {
  return (
    <Link to={to} className={`menu-link ${className ? className : ''}`} target={target}>
      {label}
    </Link>
  )
}

export default MenuLink