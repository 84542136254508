import { useEffect, memo } from 'react'
import Modal from '../../Modal'
import { useGetPickupPointsYandexQuery } from '../../../features/backend/backendApiSlice'
import { YMaps, Map } from "@pbe/react-yandex-maps"
import MapClusterer from './Map/MapClusterer'

const CartFormModalPickupYandex = ({ isOpen, onClose, onSelect, query, validateField, setDeliveryPriceYandex }) => {
  const skip = (!query?.city) ? true : false
  const { data = {}, isLoading, isFetching } = useGetPickupPointsYandexQuery(
    query,
    { skip },
  )

  useEffect(() => {
    if (data.points?.length && data.points[0]?.id) {
      setDeliveryPriceYandex(data.points[0].id)
    } else if (data?.error) {
      setDeliveryPriceYandex(false)
    }
  }, [data])

  return (
    <Modal heading="Пункты самовывоза" isOpen={isOpen} onClose={onClose}>
      {(isLoading || isFetching) ? <p>Загрузка...</p> :
       (!data?.points?.length || !data.points[0]?.position?.latitude || !data.points[0]?.position?.longitude) ? <p>Пункты самовывоза Яндекс отсутствуют в вашем городе</p> : (
        <div className="yandex-map-wrapper">
          <YMaps>
            <Map
              state={{
                center: [
                  data.points[0].position.latitude,
                  data.points[0].position.longitude,
                ],
                zoom: 10,
              }}
              width="100%"
              height="500px"
            >
              <MapClusterer
                placemarks={data.points.map((point) => ({
                  location: {
                    latitude: point.position.latitude,
                    longitude: point.position.longitude,
                  },
                  name: point.name,
                  address: point.address.full_address,
                  destinationStationId: point.id,
                }))}
                onSelect={(placemark) => {
                  onSelect(placemark.address, placemark.destinationStationId)
                  validateField(placemark.address)
                  onClose()
                }}
              />
            </Map>
          </YMaps>
        </div>
      )}
    </Modal>
  )
}

export default memo(CartFormModalPickupYandex)