import { Link } from 'react-router-dom'
import { countries } from '../data/data'

const Map = () => {
  return (
    <div className="countries-map-wrapper">
      <div className="countries-map-container">
        <img src="/images/map.png" alt="" />
        {countries.map((country, index) => (
          <Link
            key={index}
            to={`/country/${country.slug}`}
            className="countries-map-label"
            style={{
              top: country.top + '%',
              left: country.left + '%',
            }}
          >{country.name}</Link>
        ))}
      </div>
    </div>
  )
}

export default Map