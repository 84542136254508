import { useState, memo } from 'react'
import { checkCertificate } from '../../../utils/api'

const CartFormFieldCertificate = ({ value, checked, onChange, placeholder, save }) => {
  const [loading, setLoading] = useState(false)
  const [balance, setBalance] = useState(false)

  const check = async () => {
    if (!value || loading) return

    const code = value
    setLoading(true)
    setBalance(false)
    save(null, 0)
    const data = await checkCertificate(code)
    if (data?.balance > 0) {
      save(code, data.balance)
      setBalance(data.balance)
    } else {
      save(false, 0)
    }
    setLoading(false)
  }

  return (
    <div>
      <div className="cart-form-field-wrapper">
        <div className={`cart-form-field-label ${value?.length ? 'cart-form-field-label--active' : ''}`}>
          {placeholder}
        </div>
        <input
          value={value ? value : ''}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          type="text"
          className={`
            cart-form-field-wrapper-input
            ${checked === false ? ' field-invalid' : ''}
            ${value && value === checked ? ' field-valid' : ''}
          `}
          maxLength={256}
        />
        <div className="cart-form-field-arrow" onClick={check}>
          {loading ? <img src="/images/loading.svg" alt="" /> : <img src="/images/utils/arrow-big.svg" alt="" />}
        </div>
      </div>
      {balance !== false && <div className="form-success-note">Баланс: {balance / 100} р</div>}
    </div>
  )
}

export default memo(CartFormFieldCertificate)