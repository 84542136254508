import { DELIVERY_TYPES } from './enums'
import { logFrontendError } from './api'

export const getRestrictedDeliveryDates = () => {
  const restrictedDates = []

  const today = new Date()
  if ((today.getMonth() == 2 && ((today.getDate() == 7 && today.getHours() >= 14) || today.getDate() > 7))) {
    restrictedDates.push(new Date(2024, 2, 9))
    restrictedDates.push(new Date(2024, 2, 10))
    restrictedDates.push(new Date(2024, 2, 11))
  }

  return restrictedDates
}

export const isCurrentSaturdayLast = () => {
  const today = new Date()

  const currentSaturday = new Date(today)
  currentSaturday.setDate(today.getDate() + (6 - today.getDay() + 7) % 7)

  const nextSaturday = new Date(currentSaturday)
  nextSaturday.setDate(nextSaturday.getDate() + 7)

  return nextSaturday.getMonth() != currentSaturday.getMonth()
}

export const getDeliveryDateVariants = (restrictedDates = []) => {
  const restrictedDatesHardcoded = getRestrictedDeliveryDates()
  
  restrictedDates = restrictedDates.concat(restrictedDatesHardcoded)

  const dates = []
  let i = 0
  while (dates.length < 6) {
    const date = getMinDeliveryDateCourierDalli(restrictedDates)

    console.log(date)

    date.setDate(date.getDate() + i)
    if (!restrictedDates.some((elem) => elem.toLocaleDateString() == date.toLocaleDateString())) {
      dates.push(date)
    }
    i++
  }

  return dates
}

export const getMinDeliveryDateCourierDalli = (restrictedDates = []) => {
  const restrictedDatesHardcoded = getRestrictedDeliveryDates()
  
  restrictedDates = restrictedDates.concat(restrictedDatesHardcoded)

  let addedDays

  // 5 - Fri, 6 - Sat, 0 - Sun
  // if (new Date().getDay() == 5) {
  //   if (new Date().getHours() < 14) {
  //     addedDays = 1
  //   } else {
  //     addedDays = 4
  //   }
  // } else if (new Date().getDay() == 6) {
  //   addedDays = 3
  // } else if (new Date().getDay() == 0) {
  //   addedDays = 2
  // } else {
  //   if (new Date().getHours() < 14) {
  //     addedDays = 1
  //   } else {
  //     addedDays = 2
  //   }
  // }

  if (new Date().getHours() < 14) {
    addedDays = 1
  } else {
    addedDays = 2
  }

  let date = new Date(new Date().setDate(new Date().getDate() + addedDays))
  // while (restrictedDates.some((elem) => elem.toLocaleDateString() == date.toLocaleDateString())) {
  //   date.setDate(date.getDate() + 1)
  // }
  restrictedDates.forEach((elem) => {
    const restrictedDateTime = Math.floor(elem.getTime() / (1000 * 60 * 60 * 24))
    const minDateTime = Math.floor(date.getTime() / (1000 * 60 * 60 * 24))
    const currentDateTime = Math.floor((new Date()).getTime() / (1000 * 60 * 60 * 24))
    if (restrictedDateTime <= minDateTime && restrictedDateTime >= currentDateTime) {
      date.setDate(date.getDate() + 1)
    }
  })

  return date
}

export const getMinDeliveryDatePickupStore = (restrictedDates = []) => {
  const restrictedDatesHardcoded = getRestrictedDeliveryDates()
  
  restrictedDates = restrictedDates.concat(restrictedDatesHardcoded)

  let addedDays

  // 5 - Fri, 6 - Sat, 0 - Sun
  // if (new Date().getDay() == 5) {
  //   if (new Date().getHours() < 14) {
  //     addedDays = 3
  //   } else {
  //     addedDays = 4
  //   }
  // } else if (new Date().getDay() == 6) {
  //   addedDays = 3
  // } else if (new Date().getDay() == 0) {
  //   addedDays = 2
  // } else {
  //   if (new Date().getHours() < 14) {
  //     addedDays = 1
  //   } else {
  //     addedDays = 2
  //   }
  // }

  if (new Date().getHours() < 14) {
    addedDays = 1
  } else {
    addedDays = 2
  }

  let date = new Date(new Date().setDate(new Date().getDate() + addedDays))
  // while (restrictedDates.some((elem) => elem.toLocaleDateString() == date.toLocaleDateString())) {
  //   date.setDate(date.getDate() + 1)
  // }
  restrictedDates.forEach((elem) => {
    const restrictedDateTime = Math.floor(elem.getTime() / (1000 * 60 * 60 * 24))
    const minDateTime = Math.floor(date.getTime() / (1000 * 60 * 60 * 24))
    const currentDateTime = Math.floor((new Date()).getTime() / (1000 * 60 * 60 * 24))
    if (restrictedDateTime <= minDateTime && restrictedDateTime >= currentDateTime) {
      date.setDate(date.getDate() + 1)
    }
  })

  return date
}

export const getMinDeliveryDateCdek = (restrictedDates = [], offset = 0) => {
  const restrictedDatesHardcoded = getRestrictedDeliveryDates()
  
  restrictedDates = restrictedDates.concat(restrictedDatesHardcoded)
  
  let addedDays

  // 5 - Fri, 6 - Sat, 0 - Sun
  // if (new Date().getDay() == 5) {
  //   if (new Date().getHours() < 14) {
  //     addedDays = 0
  //   } else {
  //     addedDays = 4
  //   }
  // } else if (new Date().getDay() == 6) {
  //   addedDays = 3
  // } else if (new Date().getDay() == 0) {
  //   addedDays = 2
  // } else {
  //   if (new Date().getHours() < 14) {
  //     addedDays = 0
  //   } else {
  //     addedDays = 1
  //   }
  // }

  if (new Date().getHours() < 14) {
    addedDays = 1
  } else {
    addedDays = 2
  }

  // addedDays = addedDays + 2 + offset
  addedDays = addedDays + offset

  let date = new Date(new Date().setDate(new Date().getDate() + addedDays))
  // while (restrictedDates.some((elem) => elem.toLocaleDateString() == date.toLocaleDateString())) {
  //   date.setDate(date.getDate() + 1)
  // }
  restrictedDates.forEach((elem) => {
    const restrictedDateTime = Math.floor(elem.getTime() / (1000 * 60 * 60 * 24))
    const minDateTime = Math.floor(date.getTime() / (1000 * 60 * 60 * 24))
    const currentDateTime = Math.floor((new Date()).getTime() / (1000 * 60 * 60 * 24))
    if (restrictedDateTime <= minDateTime && restrictedDateTime >= currentDateTime) {
      date.setDate(date.getDate() + 1)
    }
  })

  return date
}

export const getMinDeliveryDateYandex = (restrictedDates = [], offset = 0) => {
  const restrictedDatesHardcoded = getRestrictedDeliveryDates()
  
  restrictedDates = restrictedDates.concat(restrictedDatesHardcoded)

  let addedDays

  if (new Date().getHours() < 14) {
    addedDays = 1
  } else {
    addedDays = 2
  }

  // let addedDays = 2 + offset
  addedDays = addedDays + offset

  let date = new Date(new Date().setDate(new Date().getDate() + addedDays))
  // while (restrictedDates.some((elem) => elem.toLocaleDateString() == date.toLocaleDateString())) {
  //   date.setDate(date.getDate() + 1)
  // }
  restrictedDates.forEach((elem) => {
    const restrictedDateTime = Math.floor(elem.getTime() / (1000 * 60 * 60 * 24))
    const minDateTime = Math.floor(date.getTime() / (1000 * 60 * 60 * 24))
    const currentDateTime = Math.floor((new Date()).getTime() / (1000 * 60 * 60 * 24))
    if (restrictedDateTime <= minDateTime && restrictedDateTime >= currentDateTime) {
      date.setDate(date.getDate() + 1)
    }
  })

  return date
}

export const getMinDeliveryDateRussianPost = (restrictedDates = [], offset = 0) => {
  const restrictedDatesHardcoded = getRestrictedDeliveryDates()
  
  restrictedDates = restrictedDates.concat(restrictedDatesHardcoded)

  let addedDays

  if (new Date().getHours() < 14) {
    addedDays = 1
  } else {
    addedDays = 2
  }

  // let addedDays = 3 + offset
  addedDays = addedDays + offset

  let date = new Date(new Date().setDate(new Date().getDate() + addedDays))
  // while (restrictedDates.some((elem) => elem.toLocaleDateString() == date.toLocaleDateString())) {
  //   date.setDate(date.getDate() + 1)
  // }
  restrictedDates.forEach((elem) => {
    const restrictedDateTime = Math.floor(elem.getTime() / (1000 * 60 * 60 * 24))
    const minDateTime = Math.floor(date.getTime() / (1000 * 60 * 60 * 24))
    const currentDateTime = Math.floor((new Date()).getTime() / (1000 * 60 * 60 * 24))
    if (restrictedDateTime <= minDateTime && restrictedDateTime >= currentDateTime) {
      date.setDate(date.getDate() + 1)
    }
  })

  return date
}


export const getDeliveryPriceDiscount = (deliveryType, city, cartTotal) => {
  if (deliveryType == DELIVERY_TYPES.COURIER_DALLI && city == "Москва") {
    if (cartTotal < 1500) {
      return 100
    } else {
      return 99999 // free delivery
    }
  } else {
    if (cartTotal < 1500) {
      return 100;
    } else if (cartTotal >= 1500 && cartTotal < 2500) {
      return 300
    } else if (cartTotal >= 2500 && cartTotal < 4000) {
      return 350
    } else if (cartTotal >= 4000 && cartTotal < 10000) {
      return 400
    } else {
      return 99999 // free delivery
    }
  }
}

export const isOnSale = (variation) => {
  if ((variation?.sale_start && new Date() >= new Date(variation.sale_start))
   && (variation?.sale_end && new Date() <= new Date(variation.sale_end))
   && variation?.price_sale) {
    return true
  }
  
  return false
}

export const logError = async (error, { componentStack }) => {
  // console.dir(error)
  // console.log('logging error: ', error, 'stack: ', componentStack)
  const data = 'Error: ' + error.message + '\n' + error.stack + '\n' + 'Stack: ' + componentStack

  const response = await logFrontendError({error: data})
  console.log(response)
}