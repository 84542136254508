import { memo } from 'react'

const CartFormFieldModalTrigger = ({ label, note, onClick, isInvalid }) => {
  return (
    <div onClick={onClick}>
      <div className="cart-form-field-wrapper">
        <div className={`cart-form-field-wrapper-modal-trigger  ${isInvalid ? 'field-invalid' : ''}`}>
          <div>
            {label}
          </div>
          <div><img src="/images/utils/arrow-down.svg" alt="" /></div>
        </div>
      </div>
      {note && <span className="cart-form-field-modal-trigger-note">{note}</span>}
    </div>
  )
}

export default memo(CartFormFieldModalTrigger)