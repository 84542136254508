import { useState, useRef, memo } from 'react'
import { AddressSuggestions } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'

const FormFieldAddress = ({ address, onChange, isInvalid, validateField }) => {
  const suggestionsRef = useRef(null)

  const handleValidate = (value) => {
    validateField(value)
  }

  const handleChangeDeliveryAddress = (value) => {
    if (value.data) {
      suggestionsRef.current.setInputValue(value.value)
      onChange({
        country: value.data.country,
        country_iso_code: value.data.country_iso_code,
        region: value.data.region,
        city: value.data.city,
        city_fias: value.data.fias_id,
        city_kladr_id: value.data.kladr_id,
        city_postal_code: value.data.postal_code,
        address: value.value,
      })
      handleValidate(value.value)
    }
  }

  const [active, setActive] = useState(false)

  const handleFillDeliveryAddress = (value) => {
    if (value.length > 0) {
      setActive(true)
    } else {
      setActive(false)
    }
  }

  return (
    <div className="cart-form-field-wrapper">
      <div className={`cart-form-field-label ${active ? 'cart-form-field-label--active' : ''}`}>
        Город, Улица, Дом, Корпус/Строение
      </div>
      <AddressSuggestions
        ref={suggestionsRef}
        filterToBound='house'
        token={import.meta.env.VITE_DADATA_API_KEY}
        value={{value: address}}
        onChange={handleChangeDeliveryAddress}
        inputProps={{
          // name: 'full_address',
          placeholder: 'Город, Улица, Дом, Корпус/Строение',
          className: `cart-form-field-wrapper-input ${isInvalid === true ? 'field-invalid' : ''}`,
          onKeyUp: (e) => {
            handleFillDeliveryAddress(e.target.value);
            // handleFullAddressValidate(e.target.value);
          },
          onBlur: (e) => handleValidate(e.target.value),
        }}
      />
    </div>
  )
}

export default memo(FormFieldAddress)