import { useState } from 'react'
import { Link } from "react-router-dom"

const BannerCookies = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false)

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', '1')
    setCookiesAccepted(true)
  }

  const show = (cookiesAccepted != true && localStorage.getItem('cookiesAccepted') != '1')

  if (!show) {
    return null
  }

  return (
    <div className="floating-banner">
      <div className="floating-banner-heading">Мы используем файлы cookies</div>
      <div className="floating-banner-text">Мы&nbsp;используем файлы cookies, чтобы сделать сайт удобным для вас. Продолжая использование сайта, вы&nbsp;соглашаетесь с&nbsp;этим.<br />Подробности&nbsp;&mdash; в&nbsp;<Link to="/user-agreement">пользовательском соглашении</Link>.</div>
      <div className="floating-banner-button" onClick={handleAcceptCookies}>Понятно</div>
    </div>
  )
}

export default BannerCookies