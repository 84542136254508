import { memo } from 'react'

const FormFieldText = ({ value, onChange, placeholder, isInvalid, validateField, type = 'text', note, disabled = false, className = '' }) => {
  return (
    <div className={`cart-form-field-wrapper ${className}`}>
      <div className={`cart-form-field-label ${value?.length ? 'cart-form-field-label--active' : ''}`}>
        {placeholder}
      </div>
      <input
        value={value ? value : ''}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => validateField ? validateField(e.target.value) : true}
        placeholder={placeholder}
        type={type}
        className={`cart-form-field-wrapper-input ${isInvalid === true ? 'field-invalid' : ''} ${disabled === true ? 'field-disabled' : ''}`}
        maxLength={256}
        disabled={disabled}
      />
      {note && note}
    </div>
  )
}

export default memo(FormFieldText)