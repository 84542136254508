import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import ScrollToTop from "./components/Layout/ScrollToTop"
import App from "./App"
import { store } from "./app/store"
import "./styles/main.css"
import "./styles/1-xs.css"
import "./styles/2-sm.css"
import "./styles/3-md.css"
import "./styles/4-lg.css"
import "./styles/5-xl.css"
import "./styles/6-xxl.css"

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
