import { createSlice } from "@reduxjs/toolkit"
import { isOnSale } from '../../utils/functions'

const initialState = []

// If you are not using async thunks you can use the standalone `createSlice`.
export const cartItemsSlice = createSlice({
  name: "cartItems",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: create => ({
    cartItemAdded: create.reducer((state, action) => {
      const existingItem = state.find(item => item.product_id === action.payload.product_id && item.variation.id === action.payload.variation.id)
      if (existingItem) {
        existingItem.qty += action.payload.qty
        return
      }
      state.push(action.payload)
    }),
    cartItemUpdatedQty: create.reducer((state, action) => {
      const index = state.findIndex(item => item.product_id === action.payload.product_id && item.variation.id === action.payload.variation.id)
      state[index].qty = action.payload.qty
    }),
    cartItemUpdatedVariation: create.reducer((state, action) => {
      const index = state.findIndex(item => item.product_id === action.payload.product_id && item.variation.id === action.payload.variation.id)
      if (index === -1) {
        return
      }
      state[index].variation = action.payload.variation
      state[index].is_on_sale = isOnSale(action.payload.variation)
    }),
    cartItemRemoved: create.reducer((state, action) => {
      return state.filter(item => !(item.product_id === action.payload.product_id && item.variation?.id === action.payload.variation?.id))
    }),
    cartItemsCleared: create.reducer((state, action) => {
      return []
    }),
  }),
  selectors: {
    selectCartItems: state => state,
    selectCartItemsCount: state => state.length,
    selectCartItemsTotalSum: state => state.reduce((total, item) => {
      if (isOnSale(item.variation)) {
        return total + item.qty * item.variation?.price_sale
      }
      return total + item.qty * item.variation?.price
    }, 0) / 100,
    selectCartItemsTotalQty: state => state.reduce((total, item) => total + item.qty, 0),
    selectCartItemsTotalWeight: state => state.reduce((total, item) => total + item.qty * item.variation?.weight, 0),
  },
})

export const { cartItemAdded, cartItemUpdatedQty, cartItemUpdatedVariation, cartItemRemoved, cartItemsCleared } = cartItemsSlice.actions

export const { selectCartItems, selectCartItemsCount, selectCartItemsTotalSum, selectCartItemsTotalQty, selectCartItemsTotalWeight } = cartItemsSlice.selectors