import { team } from '../../data/data'
import { Link } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'

const AboutUsTeam = () => {
  return (
    <div className="about-us-team-slider">
      <div className="styled-splide-container">
        <Splide options={{
          perPage: 4,
          perMove: 1,
          gap: '15px',
          pagination: false,
          breakpoints: { 479: { perPage: 1 }, 767: { perPage: 2 }, 991: { perPage: 3 } }
        }}>
          {team.map((elem, key) => (
            <SplideSlide key={key}>
              <div className="about-us-team-member">
                <div className="about-us-team-member__photo"><img src={elem.image} /></div>
                <div className="about-us-team-member__name">{elem.name}</div>
                <div className="about-us-team-member__position">{elem.position}</div>
                {/* <Link className="about-us-team-member__favorite-coffee" to={elem.favoriteCoffee}>Любимый кофе</Link> */}
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  )
}

export default AboutUsTeam