import { memo } from 'react'

const CartFormDeliveryTime = ({ deliveryTimeVariants, deliveryTime, onChange }) => {
  return deliveryTimeVariants?.length && deliveryTimeVariants.map((elem, index) => <div
    key={index}
    className={`cart-form-pellet ${deliveryTime === elem ? 'active' : ''}`}
    onClick={() => onChange(elem)}
  >
    {elem}
  </div>)
}

export default memo(CartFormDeliveryTime)