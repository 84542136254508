import { memo } from 'react'

const FormFieldCheckbox = ({ label, active = false, onChange, isValid }) => {
  return (
    <div className={`cart-form-field-wrapper-checkbox`} onClick={() => onChange(!active)}>
      <div className={`cart-form-field-checkbox-tick ${!isValid ? 'field-invalid' : ''}`}>
        {active && <div className="cart-form-field-checkbox-tick-active"></div>}
      </div>
      <div>{label}</div>
    </div>
  );
}

export default memo(FormFieldCheckbox)