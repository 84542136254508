const ProductPageImage = ({ fileName, alt }) => {
  const extension = fileName.split('.').pop()

  if ([ 'mp4', 'avi', 'webm', 'mov' ].includes(extension)) {
    return <video controls autoplay="autoplay" muted style={{width: '100%', height: '100%'}}>
      <source src={import.meta.env.VITE_BACKEND_URL + fileName} type={`video/mp4`} />
    </video>
  }

  return <img src={import.meta.env.VITE_BACKEND_URL + fileName} alt={alt} />
}

export default ProductPageImage