import { useState, memo } from 'react'
import Modal from '../Modal'
import useForm from '../../hooks/useForm'
import { useGetUserQuery, useGetReviewsQuery } from '../../features/backend/backendApiSlice'
import { reviewCategories } from '../../utils/enums'
import FormFieldTextarea from '../Form/FormFieldTextarea'
import { storeReview } from '../../utils/api'
import { tasteTabs, tasteGroups, tastes } from '../../data/data'
import Taste from '../Taste'

const ProfileModalReview = ({ isOpen, onClose, product = null, callback = null }) => {
  const { refetch: refetchUser } = useGetUserQuery()
  const { refetch: refetchReviews } = useGetReviewsQuery()

  const {
    fields,
    invalidFields,
    validateFieldMemoized,
    setField,
    setFieldMemoized,
    setFieldMultiple,
    handleSubmit,
    isSubmitting,
    error,
  } = useForm({
    review_category_id: product ? 1 : 2,
    product_id: product ? product.id : null,
  }, {
    rating: (value) => value < 1 || value > 5,
    text: (value) => value?.length < 10,
  }, async (fields, setError) => {
    const response = await storeReview(fields)
    await refetchUser()
    await refetchReviews()
    if (callback) await callback()
    onClose()
  })

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [ratingHover, setRatingHover] = useState(0)

  const [tasteView, setTasteView] = useState(false)
  const [tasteTab, setTasteTab] = useState(1)
  const [selectedTastes, setSelectedTastes] = useState([])

  return (
    <Modal heading={tasteView ? 'Выберите вкусы' : 'Напишите отзыв'} isOpen={isOpen} onClose={onClose} className={tasteView ? 'long' : ''}>
      {tasteView && <div>
        <div className="review-tastes">
          <div className="review-tastes-tabs">
            {tasteTabs.map((tab) => (
              <div key={tab.id} className={`review-tastes-tab ${tasteTab === tab.id ? 'active' : ''}`} onClick={() => setTasteTab(tab.id)}>{tab.name}</div>
            ))}
          </div>
          <div className="review-tastes-groups">
            {tasteGroups.filter(group => group.tabId === tasteTab).map((group) => (
              <div key={group.id} className="review-tastes-group">
                <div className="review-tastes-group-label">{group.name}</div>
                <div className="review-tastes-group-tastes">
                  {tastes.filter(taste => taste.groupId === group.id).map((taste) => (
                    <Taste
                      key={taste.id}
                      name={taste.name}
                      color={taste.color}
                      addable={true}
                      onClick={() => setSelectedTastes([...selectedTastes, taste.id])}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="review-tastes-selected">
          <div className="review-tastes-heading">
            <div className="review-tastes-heading-label">Ваш выбор</div>
            <div className="review-tastes-heading-clear" onClick={() => setSelectedTastes([])}>Сбросить</div>
          </div>
          {tasteTabs.map((tab) => (
            <div key={tab.id} className="review-tastes-selected-tab">
              <div className="review-tastes-selected-tab-label">{tab.name}:</div>
              <div className="review-tastes-selected-tab-tastes">
                {tastes.filter(taste => selectedTastes.includes(taste.id) && tasteGroups.filter(group => group.tabId == tab.id).map(group => group.id).includes(taste.groupId)).map((taste) => (
                  <Taste
                    key={taste.id}
                    name={taste.name}
                    color={taste.color}
                    removable={true}
                    onClick={() => setSelectedTastes(selectedTastes.filter(id => id !== taste.id))}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="review-tastes-actions">
          <button type="button" className="button-big-primary-wide" onClick={() => {
            setField('tastes')(selectedTastes)
            setTasteView(false)
          }}>Сохранить изменения</button>
        </div>
      </div>}
      
      <form onSubmit={handleSubmit}>
        <div className={`profile-modal-review ${tasteView ? 'display-none' : ''}`}>
          <div>
            {product ? <div className="review-meta__type">{product.name}</div> : <div className="profile-modal-review-dropdown">
              <div className="dropdown-container">
                <div className="dropdown-label" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <div>{reviewCategories.find((category) => category.id === fields.review_category_id).label}</div>
                  <img src="/images/utils/arrow-down.svg" />
                </div>
                <div className={`dropdown-content ${isDropdownOpen ? 'active' : ''}`}>
                  {reviewCategories.filter(category => category.id != 1).map((category) => (
                    <a key={category.id} onClick={() => {
                      setField('review_category_id')(category.id)
                      setIsDropdownOpen(false)
                    }}>{category.label}</a>
                  ))}
                </div>
              </div>
            </div>}
          </div>
          {product && <div>
            <button type="button" className="button-big-primary-wide" onClick={() => setTasteView(true)}>Выберите вкусы</button>
          </div>}
          {fields?.tastes?.length > 0 && <div className="review-tastes-selected-summary">
            {tastes.filter(taste => fields.tastes.includes(taste.id)).map((taste) => (
              <Taste
                key={taste.id}
                name={taste.name}
                color={taste.color}
              />
            ))}
          </div>}
          <FormFieldTextarea
            value={fields?.text ?? ''}
            onChange={setFieldMemoized('text')}
            placeholder={'Комментарий'}
            isInvalid={invalidFields.text}
            validateField={validateFieldMemoized('text')}
          />
          <div className="profile-modal-review-rating-container">
            <div className={`profile-modal-review-rating-label ${invalidFields.rating ? 'text-invalid' : ''}`}>Ваша оценка</div>
            <div className="profile-modal-review-rating">
              {[1,2,3,4,5].map((rating) => (
                <img
                  key={rating}
                  src={`/images/utils/star-${ratingHover > 0
                    ? (ratingHover >= rating ? 'blue' : 'empty')
                    : (fields.rating >= rating ? 'blue' : 'empty')
                  }.svg`}
                  onClick={() => setField('rating')(rating)}
                  onMouseOver={() => setRatingHover(rating)}
                  onMouseOut={() => setRatingHover(0)}
                />
              ))}
            </div>
          </div>
          <button type="submit" className={`button-big-primary-wide ${isSubmitting ? 'disabled' : ''}`}>
            {isSubmitting ? 'Отправляем...' : 'Отправить'}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default memo(ProfileModalReview)