import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { catalogSort, CATALOG_SORT } from "../../utils/enums"
import { catalogSortSelected, selectCatalogSort } from "../../features/catalogFilters/catalogFiltersSlice"

const CatalogSorter = ({ isRight = false, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false)

  const dispatch = useDispatch()

  const handleSortSelect = (sort) => {
    dispatch(catalogSortSelected(sort))
    setIsOpen(false)
  }

  const sort = useSelector(selectCatalogSort)

  return (
    <div className={`catalog-sorter ${className}`}>
      <div className="dropdown-container">
        <div className="dropdown-label" onClick={() => setIsOpen(!isOpen)}>
          <div>{sort?.label ? sort.label : catalogSort.find((sort) => sort.type === CATALOG_SORT.DEFAULT).label}</div>
          <img src="/images/utils/arrow-down.svg" />
        </div>
        <div className={`dropdown-content ${isRight && 'dropdown-content--right'} ${isOpen ? 'active' : ''}`}>
          {catalogSort.map((sort, key) => (
            <a key={key} onClick={() => handleSortSelect(sort)}>{sort.label}</a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CatalogSorter