const Taste = ({ name, color, addable = false, removable = false, onClick = () => {} }) => {
  const hex = color.replace('#', '').split('')
  const r = parseInt(hex[0] + hex[1], 16)
  const g = parseInt(hex[2] + hex[3], 16)
  const b = parseInt(hex[4] + hex[5], 16)

  const textColor = (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000' : '#fff'

  return (
    <div
      className="review-taste"
      style={{ backgroundColor: color, cursor: (addable || removable) && 'pointer' }}
      onClick={onClick}
    >
      <div
        className="review-taste-label"
        style={{ color: textColor }}
      >
        {name}
      </div>
      {(addable || removable) && <div className={`review-taste-action ${removable ? 'removable' : ''}`} />}
    </div>
  )
}

export default Taste