import CatalogFiltersPellet from './CatalogFiltersPellet'

const CatalogFiltersQuick = ({ category, filters }) => {
  if (filters.length === 0) {
    return null
  }

  return (
    <div className="catalog-filters-section catalog-filters-section--quick">
      <div className="catalog-filters-pellets-wrapper catalog-filters-pellets-wrapper--quick">
        {filters.map((option, key) => (
          <CatalogFiltersPellet key={key} category={category} option={option} />
        ))}
      </div>
    </div>
  )
}

export default CatalogFiltersQuick