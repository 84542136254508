import { useEffect } from 'react'
import { useGetCoursesQuery } from '../features/backend/backendApiSlice'
import { Link, useParams } from 'react-router-dom'
import useTitle from "../hooks/useTitle"
import NotFound from '../components/NotFound'

const EducationOneView = () => {
  const { data, isError, isLoading, isSuccess } = useGetCoursesQuery()
  
  const { slug } = useParams()

  const course = data ? data.find((course) => course.slug == slug) : null

  useTitle(course?.name)

  const loadScript = function(id) {
    const tag = document.createElement('script')

    tag.id = `timepad_script_${id}`
    tag.type = 'text/javascript'
    tag.async = 'async'
    tag.defer = 'defer'
    tag.setAttribute('data-timepad-customized', '157366')
    tag.setAttribute('data-timepad-widget-v2', 'event_register')
    tag.src = 'https://timepad.ru/js/tpwf/loader/min/loader.js'
    // tag.text = inner
    tag.appendChild(document.createTextNode('(function(){return {"event" : {"id" : ' + id + ' }, "hidePreloading" : true, "display" : "popup", "popup" : {"triggerSelector" : "#timepad_twf_register_' + id + '"}}})();'))
    // console.log(tag)
    document.body.appendChild(tag)
  }

  useEffect(() => {
    if (!course) return
    const loadedScript = document.getElementById(`timepad_script_${course.timepad_id}`)
    if (!loadedScript) {
      loadScript(course.timepad_id)
    }
  }, [course])

  if (isSuccess && !course) {
    return <NotFound />
  }

  return (
    <div className="container">
      <Link to="/education-all" className="link-action-back mb-40">
        <img src="/images/utils/back.svg" alt="" />
        <div>Назад ко всем курсам</div>
      </Link>
      {isError && <div>Произошла ошибка при загрузке статьи</div>}
      {isLoading && <div className="loading-rocket"><img src="/images/loading-rocket.svg" alt="" /></div>}
      {isSuccess && <div>
        <div className="heading-h1">{course?.name}</div>
        <div className="course-container">
          <div className="course-container-description">
            <div className="course-container-description-short mb-40">{course.description_short}</div>
            <p className="gray-text">Длительность:</p>
            <p className="course-container-meta">{course.duration}</p>
            <p className="gray-text">Стоимость:</p>
            <p className="course-container-meta">{course.price} <span className="commissioner-700">₽</span></p>
            <button className="button-big-primary-wide" id={`timepad_twf_register_${course.timepad_id}`}>Записаться на курс</button>
          </div>
          <div className="course-container-thumbnail"><img src={import.meta.env.VITE_BACKEND_URL + course.thumbnail} alt="" /></div>
        </div>
        <div className="course-descriptions">
          {course.description && <div className="course-descriptions-item">
            <div>Описание</div>
            <div>{course.description}</div>
          </div>}
          {course.description_for_whom && <div className="course-descriptions-item">
            <div>Для кого этот курс</div>
            <div>{course.description_for_whom}</div>
          </div>}
          {course.description_what_will_learn && <div className="course-descriptions-item">
            <div>Чему вы научитесь</div>
            <div>{course.description_what_will_learn}</div>
          </div>}
        </div>
      </div>}
    </div>
  )
}

export default EducationOneView