import { createSlice } from "@reduxjs/toolkit"

const initialState = []

// If you are not using async thunks you can use the standalone `createSlice`.
export const recentlySeenSlice = createSlice({
  name: "recentlySeen",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: create => ({
    recentlySeenAdded: create.reducer((state, action) => {
      return [{ id: action.payload }, ...state.filter(item => item.id !== action.payload)]
    }),
    recentlySeenCleared: create.reducer((state, action) => {
      return []
    }),
  }),
  selectors: {
    selectRecentlySeen: state => state,
  },
})

export const { recentlySeenAdded, recentlySeenCleared } = recentlySeenSlice.actions

export const { selectRecentlySeen } = recentlySeenSlice.selectors