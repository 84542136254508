import { useParams } from 'react-router-dom'
import CatalogControls from '../components/Catalog/CatalogControls'
import Product from "../components/Product"
import { useGetProductsQuery } from "../features/backend/backendApiSlice"
import { selectCatalogFilters, selectCatalogSort } from '../features/catalogFilters/catalogFiltersSlice'
import { useSelector } from 'react-redux'
import { CATALOG_SORT } from '../utils/enums'
import useTitle from "../hooks/useTitle"
import NotFound from '../components/NotFound'

const CatalogView = () => {
  const { slug } = useParams()

  // TODO this is dirty, refactor
  if (!['coffee', 'coffee-drips', 'coffee-capsules', 'tea', 'notcoffee', 'accessories', 'archive', 'search'].includes(slug)) {
    return <NotFound />
  }

  if (slug == 'coffee' || slug == 'coffee-drips' || slug == 'coffee-capsules') {
    useTitle('Заказать кофе в Москве - Купить кофе с доставкой по выгодной цене в интернет-магазине')
  }
  if (slug == 'tea') {
    useTitle('Чай - Купить чай по выгодной цене в интернет-магазине')
  }
  if (slug == 'notcoffee') {
    useTitle('Не кофе')
  }
  if (slug == 'accessories') {
    useTitle('Аксессуары для кофе - Купить аксессуары для приготовления кофе и чая в интернет-магазине')
  }
  if (slug == 'archive') {
    useTitle('Архив')
  }
  if (slug == 'search') {
    useTitle('Поиск')
  }

  const { data, isError, isLoading, isSuccess } = useGetProductsQuery()

  const urlParams = new URLSearchParams(window.location.search)
  const q = urlParams.get('q')

  const products = data ? data.filter((product) => {
    let result = product.categories.find(category => category.slug == slug)
    // if this is a search, get products from all categories
    if (slug == 'search') {
      result = true
    }
    if (q) {
      result = result && product.name.toLowerCase().includes(q.toLowerCase())
    }
    return result
  }) : []

  console.log(products)
  const filters = useSelector(selectCatalogFilters)
  const categoryFilters = filters.filter(filter => filter.category === slug)

  const productsFiltered = products.filter((product) => categoryFilters.every(filter => product.attributes.find(attribute => attribute.options.find(option => option.id == filter.optionId))))

  const sort = useSelector(selectCatalogSort)
  if (sort != null && sort != CATALOG_SORT.DEFAULT) {
    productsFiltered.sort((a, b) => {
      switch (sort.type) {
        case CATALOG_SORT.PRICE_ASC:
          // return a.variations[0].price - b.variations[0].price
          // get the variation with the lowest price
          const priceA = a.variations.reduce((acc, variation) => variation.price < acc ? variation.price : acc, a.variations[0].price)
          const priceB = b.variations.reduce((acc, variation) => variation.price < acc ? variation.price : acc, b.variations[0].price)
          return priceA - priceB
        case CATALOG_SORT.PRICE_DESC:
          // return b.variations[0].price - a.variations[0].price
          // get the variation with the lowest price
          const priceA2 = a.variations.reduce((acc, variation) => variation.price < acc ? variation.price : acc, a.variations[0].price)
          const priceB2 = b.variations.reduce((acc, variation) => variation.price < acc ? variation.price : acc, b.variations[0].price)
          return priceB2 - priceA2
        case CATALOG_SORT.NEWEST:
          return new Date(b.created_at) - new Date(a.created_at)
        case CATALOG_SORT.OLDEST:
          return new Date(a.created_at) - new Date(b.created_at)
      }
    })
  }
  
  if (sort == null || sort?.type == CATALOG_SORT.DEFAULT) {
    productsFiltered.sort((a, b) => a.priority - b.priority)
  }

  return (
    <div className="container">
      <CatalogControls category={slug} products={products} />
      <div className="catalog-products-container">
        {isLoading && <div>Каталог загружается...</div>}
        {isError && <div>Произошла ошибка при загрузке каталога</div>}
        {isSuccess && (
          productsFiltered.length == 0
            ? <div>По выбранным параметрам ничего не найдено, измените фильтры и попробуйте еще раз</div>
            : productsFiltered.map((product) => (
              <Product key={product.id} product={product} />
            ))
          )
        }
      </div>
    </div>
  )
}

export default CatalogView