import { useState, useRef, memo } from 'react'
import { AddressSuggestions } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'

const CartFormFieldAddress = ({ cityFias, address, onChange, isInvalid, validateField }) => {
  const suggestionsRef = useRef(null)

  const handleValidate = (value) => {
    validateField(value)
  }

  const handleChangeDeliveryAddress = (value) => {
    if (value.data) {
      suggestionsRef.current.setInputValue(value.value)
      onChange(value.value)
      handleValidate(value.value)
    }
  }

  const [active, setActive] = useState(false)

  const handleFillDeliveryAddress = (value) => {
    if (value.length > 0) {
      setActive(true)
    } else {
      setActive(false)
    }
  }

  return (
    <div className="cart-form-field-wrapper">
      <div className={`cart-form-field-label ${active ? 'cart-form-field-label--active' : ''}`}>
        Улица, Дом, Корпус/Строение
      </div>
      <AddressSuggestions
        ref={suggestionsRef}
        filterToBound='house'
        filterLocations={{fias_id: cityFias}}
        token={import.meta.env.VITE_DADATA_API_KEY}
        value={{value: address}}
        onChange={handleChangeDeliveryAddress}
        inputProps={{
          // name: 'full_address',
          placeholder: 'Улица, Дом, Корпус/Строение',
          className: `cart-form-field-wrapper-input ${isInvalid === true ? 'field-invalid' : ''}`,
          onKeyUp: (e) => {
            handleFillDeliveryAddress(e.target.value);
            // handleFullAddressValidate(e.target.value);
          },
          onBlur: (e) => handleValidate(e.target.value),
        }}
      />
    </div>
  )
}

export default memo(CartFormFieldAddress)