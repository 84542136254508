import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { pathname } = useLocation()
  const originalPathname = useRef(pathname)

  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary()
    }
  }, [pathname, resetErrorBoundary])

  const reloadPage = () => {
    window.location.reload()
  }

  // test comment for rerunning

  return (
    <div className="container">
      <div className="heading-h1">Ой!</div>
      <p>Произошла ошибка: <span style={{ color: "red" }}>{error.message}</span></p>
      <p>Мы уже получили уведомление и работаем над её устранением.</p>
      <p>{new Date().toLocaleString('ru-RU')}</p>
      <button className="button-big-primary-wide" onClick={reloadPage}>Перезагрузить страницу</button>
    </div>
  )
}

export default ErrorFallback