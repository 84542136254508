import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-container">
          <div>
            <p className="mb-10 footer-bolder">Продукция</p>
            <p className="mb-5 muted"><Link to="/catalog/coffee">Кофе</Link></p>
            <p className="mb-5 muted"><Link to="/catalog/tea">Чай</Link></p>
            <p className="mb-20 muted"><Link to="/catalog/accessories">Аксессуары</Link></p>
            <p className="mb-10 footer-bolder">Образование</p>
            <p className="mb-5 muted"><Link to="/education-all">Обучение</Link></p>
            <p className="mb-5 muted"><Link to="/knowledge">Знания</Link></p>
            <p className="mb-5 muted"><Link to="/coffee-dictionary">Кофейный словарь</Link></p>
          </div>
          <div>
            <p className="mb-15"><Link to="https://b2b-rockets.coffee/" target="_blank">Сотрудничество</Link></p>
            <p className="mb-15"><Link to="/loyalty-system">Бонусная система</Link></p>
            <p className="mb-15"><Link to="/coffee-shops">Наши проекты</Link></p>
            <p className="mb-15"><Link to="/shipping-and-payment">Доставка и оплата</Link></p>
            <p className="mb-15"><Link to="/reviews">Отзывы</Link></p>
            <p className="mb-15"><Link to="/faq">FAQ</Link></p>
            <p className="mb-15"><Link to="/about-us">О нас</Link></p>
            <p className="mb-15"><Link to="/contacts">Контакты</Link></p>
          </div>
          <div className="footer-xs-full-width">
            <p className="mb-10 muted">Для звонков по России</p>
            <p className="mb-20"><a href="tel:8 800 777-04-14">8 800 777-04-14</a></p>
            <p className="mb-10 muted">Мы принимаем звонки</p>
            <p className="mb-20 footer-bolder">Ежедневно: c 10-00 до 18-00</p>
            <div className="hide-xxl">
              <p className="mb-10 muted">По общим вопросам</p>
              <p className="mb-20"><a href="mailto:info@rockets.coffee">info@rockets.coffee</a></p>
              <p className="mb-10 muted">По заказам интернет-магазина или для обратной связи</p>
              <p className="mb-20"><a href="mailto:shop@rockets.coffee">shop@rockets.coffee</a></p>
              <p className="mb-10 muted">По вопросам сотрудничества</p>
              <p className="mb-20"><a href="mailto:b2b@rockets.coffee">b2b@rockets.coffee</a></p>
            </div>
          </div>
          <div className="footer-xs-full-width">
            <p className="mb-10 muted">Адрес производства</p>
            <p className="mb-20 footer-bolder">108820, Россия, Москва,<br />п. завода Мосрентген,<br />ул. Героя России Соломатина,<br />д. 6, к. 23</p>
            <p className="mb-10 muted">Адрес офиса</p>
            <p className="mb-20 footer-bolder">115054 Москва<br />ул. Валовая, 35</p>
            <p className="mb-20 hide-xxl"><a href="https://t.me/rocketscoffee">Telegram</a></p>
          </div>
          <div className="hide-xl hide-lg hide-md hide-sm hide-xs">
            <p className="mb-10 muted">По общим вопросам</p>
            <p className="mb-20"><a href="mailto:info@rockets.coffee">info@rockets.coffee</a></p>
            <p className="mb-10 muted">По заказам интернет-магазина или для обратной связи</p>
            <p className="mb-20"><a href="mailto:shop@rockets.coffee">shop@rockets.coffee</a></p>
            <p className="mb-10 muted">По вопросам сотрудничества</p>
            <p className="mb-20"><a href="mailto:b2b@rockets.coffee">b2b@rockets.coffee</a></p>
            <p className="mb-20"><a href="https://t.me/rocketscoffee">Telegram</a></p>
          </div>
        </div>
        <div className="footer-container">
          <div className="footer-copyright">
            <p className="mb-5 muted">Copyright 2014-2024 ROCKETS COFFEE ROASTERS</p>
            <p className="mb-40 muted">Rockets &reg; is a registered Trademark</p>
            {/* <p className="mb-10 muted hide-xxl hide-xl"><Link to="/user-agreement">Пользовательское соглашение</Link></p> */}
          </div>
          <div className="footer-logo-cards">
            <p className="mb-40 muted hide-xxl"><Link to="/user-agreement">Пользовательское соглашение</Link></p>
            <img src="/images/footer-logo-cards.jpg" alt="" />
          </div>
          <div className="hide-xl hide-lg hide-md hide-sm hide-xs">
            <p className="mb-40 muted"><Link to="/user-agreement">Пользовательское соглашение</Link></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer