import { memo } from 'react'
import Modal from '../../Modal'
import { useGetPickupPointsCdekQuery } from '../../../features/backend/backendApiSlice'
import { YMaps, Map } from "@pbe/react-yandex-maps"
import MapClusterer from './Map/MapClusterer'

const CartFormModalPickupCdek = ({ isOpen, onClose, onSelect, query, validateField }) => {
  const skip = (!query?.countryIsoCode || !query?.city) ? true : false
  const { data = {}, isLoading, isFetching } = useGetPickupPointsCdekQuery(
    query,
    { skip },
  )

  return (
    <Modal heading="Пункты самовывоза" isOpen={isOpen} onClose={onClose}>
      {(isLoading || isFetching) ? <p>Загрузка...</p> :
       (!data?.points?.length || !data?.city?.latitude || !data?.city?.longitude) ? <p>Пункты самовывоза СДЭК отсутствуют в вашем городе</p> : (
        <div className="yandex-map-wrapper">
          <YMaps>
            <Map
              state={{
                center: [
                  data.city.latitude,
                  data.city.longitude,
                ],
                zoom: 10,
              }}
              width="100%"
              height="500px"
            >
              <MapClusterer
                placemarks={data.points.map((point) => ({
                  location: {
                    latitude: point.location.latitude,
                    longitude: point.location.longitude,
                  },
                  name: point.name,
                  address: point.location.address,
                  workTime: point.work_time,
                  addressFull: point.location.address_full,
                }))}
                onSelect={(placemark) => {
                  onSelect(placemark.address, placemark.addressFull)
                  validateField(placemark.address)
                  onClose()
                }}
              />
            </Map>
          </YMaps>
        </div>
      )}
    </Modal>
  )
}

export default memo(CartFormModalPickupCdek)