import { useDispatch } from 'react-redux'
import { useGetProductsQuery } from '../../features/backend/backendApiSlice'
import { cartItemUpdatedQty, cartItemRemoved } from '../../features/cartItems/cartItemsSlice'
import { isOnSale } from '../../utils/functions'

const CartItem = ({ item }) => {
  const dispatch = useDispatch()

  const { data: products } = useGetProductsQuery()

  const product = (products && products.find(elem => elem.id == item.product_id))
  const attributes_used_for_variations = product ? product.attributes.filter(attribute => attribute.is_used_for_variations == 1) : []

  const getImage = (item) => {
    if (item.variation?.images[0]?.path) {
      return import.meta.env.VITE_BACKEND_URL + item.variation?.images[0]?.path
    }
    if (product && product.variations[0]?.images[0]?.path) {
      return import.meta.env.VITE_BACKEND_URL + product.variations[0]?.images[0]?.path
    }
    return // TODO return no image?
  }

  const handleUpdateQty = (item, action) => {
    let qty = item.qty
    if (action === 'lower') {
      qty = qty > 1 ? qty - 1 : qty
    } else if (action === 'raise') {
      qty = qty + 1
    }
    dispatch(cartItemUpdatedQty({ ...item, qty: qty }))
  }

  const handleRemove = (item) => {
    dispatch(cartItemRemoved(item))

    window.dataLayer.push({
      "ecommerce": {
        "currencyCode": "RUB",
        "remove": {
          "products": [
            {
              "id": product.id,
              "name": product.title,
              "price": isOnSale(item.variation) ? (item.variation.price_sale / 100) : (item.variation.price / 100),
              // "brand": "Яндекс / Яndex",
              "category": product.categories.map(category => category.name).join(' / '),
              "variant": item.variation.options.map(option => option.name).join(' / '),
              "quantity": item.qty,
              // "list": "Одежда",
              // "position": 1
            },
          ]
        }
      }
    })
  }

  return (
    <div className="cart-summary-product">
      <div className="cart-summary-product-remove" onClick={() => handleRemove(item)}><img src="/images/icons/close-gray.svg" alt="" /></div>
      <div className="cart-summary-product-info">
        <div className="cart-summary-product-info__image">
          <img src={getImage(item)} alt="" />
        </div>
        <div className="cart-summary-product-info__props">
          <div className="cart-summary-product-info__name">{(products && products.find(elem => elem.id == item.product_id)) && products.find(elem => elem.id == item.product_id).name}</div>
          <div className="cart-summary-product-options">
            {item.variation?.options && item.variation.options
              .filter(option => attributes_used_for_variations.some(attribute => attribute.attribute_id === option.attribute_id))
              .map((option, index) => (
                <div key={index} className="cart-summary-product-option">
                  {option.name}
                </div>
              ))}
            <div className="cart-summary-product-option cart-summary-product-option__qty">
              <div onClick={() => handleUpdateQty(item, 'lower')}>-</div>
              <div>{item.qty}</div>
              <div onClick={() => handleUpdateQty(item, 'raise')}>+</div>
            </div>
          </div>
        </div>
      </div>
      <div className="cart-summary-product-price">{isOnSale(item.variation) ? <><s>{item.variation?.price / 100}&nbsp;<span className="commissioner-500">₽</span></s> {item.variation?.price_sale / 100}</> : item.variation?.price / 100}&nbsp;<span className="commissioner-500">₽</span></div>
    </div>
  );
}

export default CartItem;