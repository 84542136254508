const SubTab = ({ active, toggle, heading, children }) => {
  return (
    <div className={`subtab ${active ? 'active' : ''}`}>
      <div className="subtab-header" onClick={toggle}>
        <div className="subtab-heading">{heading}</div>
        <div className="subtab-icon">
          <img src="/images/utils/plus.svg" alt="" />
        </div>
      </div>
      <div className={`subtab-content ${!active ? 'display-none' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default SubTab