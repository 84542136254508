import { useState, memo } from 'react'
import Modal from '../../Modal'

const CartFormModalAddresses = ({ isOpen, onClose, user, setAddress }) => {
  const [selected, setSelected] = useState(null)

  const handleSelect = () => {
    if (selected !== null) {
      setAddress(selected)
    }
    onClose()
  }

  return (
    <Modal heading="Выберите адрес доставки" isOpen={isOpen} onClose={onClose} className="medium">
      {user?.customer?.addresses?.length > 0 ? user.customer.addresses.map((address, index) => (
        <div key={index} className="cart-form-address" onClick={() => setSelected(address)}>
          <div className="cart-form-field-radio-tick">
            {selected?.id == address.id && <div className="cart-form-field-radio-tick-active"></div>}
          </div>
          <div className="cart-form-address-data">
            <div className="cart-form-address-header">
              <div className="cart-form-address-header__name">{address.name}</div>
              {address.is_default == 1 && <div className="cart-form-address-header__default">По умолчанию</div>}
            </div>
            <div>{address.address}</div>
            <div>{address.entrance && `Подъезд ${address.entrance},`} {address.floor && `Этаж ${address.floor},`} {address.apartment && `Квартира ${address.apartment},`} {address.intercom && `Домофон ${address.intercom}`}</div>
          </div>
        </div>
      )) : 'У вас нет сохранённых адресов'}
      {user?.customer?.addresses?.length > 0 && <div className="button-big-primary-wide" onClick={handleSelect}>Выбрать</div>}
    </Modal>
  )
}

export default memo(CartFormModalAddresses)