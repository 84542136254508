import { useState } from 'react'
import useForm from '../hooks/useForm'
import FormFieldArrow from '../components/Form/FormFieldArrow'
import { Link } from 'react-router-dom'

const EmailForm = () => {
  const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms))

  const [isFormVisible, setIsFormVisible] = useState(true)

  const {
    fields,
    setFields,
    invalidFields,
    validateFieldMemoized,
    setFieldMemoized,
    setFieldMultipleMemoized,
    handleSubmit,
    isSubmitting,
  } = useForm({}, {
    email: (value) => value?.length < 1 || !value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i),
  }, async (fields) => {
    await timeout(1000)

    _rc('send', 'order', {
      'email': fields.email,
    })
    setIsFormVisible(false)

    console.log(fields)
  })

  return (
    <div className="email-subscription-form-container">
      <div className="email-subscription-form">
        <div className="heading-h2">Узнавайте о наших новостях первыми!</div>
        <div className={`form-fields-container-1col ${!isFormVisible ? 'display-none' : ''}`}>
          <FormFieldArrow
            value={fields?.email ?? ''}
            onChange={setFieldMemoized('email')}
            validateField={validateFieldMemoized('email')}
            placeholder="Адрес электронной почты"
            isInvalid={invalidFields.email}
            action={handleSubmit}
          />
        </div>
        <p className={`${isFormVisible ? 'display-none' : ''}`}>Спасибо за подписку!</p>
        <p className="gray-text email-form-text">Я даю согласие на получение материалов о продуктах, новостях и акциях, на условии <Link to="/user-agreement">политики конфиденциальности</Link></p>
      </div>
    </div>
  )
}

export default EmailForm