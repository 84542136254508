import { memo } from 'react'

const CartFormDeliveryDate = ({ deliveryDateVariants, deliveryDate, onChange }) => {
  const formatDate = (date) => {
    if (date === null) {
      return ""
    }

    const today = new Date()
    if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
      return "Сегодня"
    }

    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    if (date.getDate() === tomorrow.getDate() && date.getMonth() === tomorrow.getMonth() && date.getFullYear() === tomorrow.getFullYear()) {
      return "Завтра"
    }

    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const dayOfWeek = date.toLocaleDateString("ru-RU", { weekday: "short" }).charAt(0).toUpperCase() + date.toLocaleDateString("ru-RU", { weekday: "short" }).slice(1)

    return <><div className="cart-form-pellet-bold">{dayOfWeek}</div><div>{day}.{month}</div></>
  }

  return deliveryDateVariants?.length && deliveryDateVariants.map((elem, index) => <div
    key={index}
    className={`cart-form-pellet ${deliveryDate === elem.toLocaleDateString("ru-RU") ? 'active' : ''}`}
    onClick={() => onChange(elem.toLocaleDateString("ru-RU"))}
  >
    {formatDate(elem)}
  </div>)
}

export default memo(CartFormDeliveryDate)