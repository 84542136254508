import type { Action, ThunkAction } from "@reduxjs/toolkit"
import { combineSlices, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
// import { counterSlice } from "../features/counter/counterSlice"
// import { quotesApiSlice } from "../features/quotes/quotesApiSlice"
import { backendApiSlice } from "../features/backend/backendApiSlice"
import { cartItemsSlice } from "../features/cartItems/cartItemsSlice"
import { recentlySeenSlice } from "../features/recentlySeen/recentlySeenSlice"
import { catalogFiltersSlice } from "../features/catalogFilters/catalogFiltersSlice"
import { lastOrderSlice } from "../features/lastOrder/lastOrderSlice"

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices(backendApiSlice, cartItemsSlice, recentlySeenSlice, catalogFiltersSlice, lastOrderSlice)
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(backendApiSlice.middleware)
    },
    preloadedState,
  })
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch)
  return store
}

const preloadedState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems") || "[]"),
}

export const store = makeStore(preloadedState)

store.subscribe(() => {
  const state = store.getState()
  const cartItems = state.cartItems
  localStorage.setItem("cartItems", JSON.stringify(cartItems))
})

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
