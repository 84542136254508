import { useState, memo } from 'react'

const FormFieldImage = ({ id, current, onChange }) => {
  const [preview, setPreview] = useState(null)

  const handleChange = (e) => {
    onChange(e.target.files[0])
    var reader = new FileReader()
    reader.onload = function (e) {
      setPreview(e.target.result)
    }
    reader.readAsDataURL(e.target.files[0])
  }

  return (
    <div className="form-field-image-wrapper">
      <label
        htmlFor={id}
        className="profile-data-avatar mb-20"
      />
      <input
        id={id}
        type="file"
        onChange={handleChange}
      />
      <div className="form-field-image-edit" />
      {/* {preview ? <img src={preview} alt="preview" /> : (current && <img src={import.meta.env.VITE_BACKEND_URL + current} alt="avatar" />)} */}
      {(preview || current) &&  <div
        className="form-field-image-preview"
        style={{backgroundImage: `url(${preview ? preview : (current && import.meta.env.VITE_BACKEND_URL + current)})`}}
      />}
    </div>

    // <div className="cart-form-field-wrapper">
    //   <div className={`cart-form-field-label ${value?.length ? 'cart-form-field-label--active' : ''}`}>
    //     {placeholder}
    //   </div>
    //   <input
    //     value={value ? value : ''}
    //     onChange={(e) => onChange(e.target.value)}
    //     onBlur={(e) => validateField ? validateField(e.target.value) : true}
    //     placeholder={placeholder}
    //     type={type}
    //     className={`cart-form-field-wrapper-input ${isInvalid === true ? 'field-invalid' : ''}`}
    //     maxLength={256}
    //   />
    // </div>
  )
}

export default memo(FormFieldImage)