import { useGetCoursesQuery } from '../features/backend/backendApiSlice'
import { Link } from 'react-router-dom'
import useTitle from "../hooks/useTitle"

const EducationView = () => {
  useTitle('Обучение')

  const { data, isError, isLoading, isSuccess } = useGetCoursesQuery()

  return (
    <div className="container">
      <div className="heading-h1">Курсы и мастер-классы</div>
      <div className="tabs-container">
        <div className="tabs">
          <div className="tab-link">Курсы</div>
        </div>
        <div className="tabs-content">
          {isError && <div>Произошла ошибка при загрузке курсов</div>}
          {isLoading && <div className="loading-rocket"><img src="/images/loading-rocket.svg" alt="" /></div>}
          {isSuccess && (
            <>
              {data.length > 0 ? <div className="courses-container">
                {data.map((course) => (
                  <div className="course-item" key={course.id}>
                    <div className="course-name">{course.name}</div>
                    <div className="course-info">
                      <div className="course-info__meta">
                        <div className="course-info__duration">{course.duration}</div>
                        <div className="course-info__price">{course.price} <span className="commissioner-400">₽</span></div>
                        <div className="course-info__link">
                          <Link to={`/education-all/${course.slug}`}>Записаться</Link>
                        </div>
                      </div>
                      <div className="course-info__description-short">{course.description_short}</div>
                      <div className="course-info__link--mobile">
                        <Link to={`/education-all/${course.slug}`}>Записаться</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div> : <p>Пока нет курсов</p>}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default EducationView