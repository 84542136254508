import { useState, useCallback } from 'react'
import { useGetUserQuery } from '../../features/backend/backendApiSlice'
import ProfileModalReview from './ProfileModalReview'
import ProfileReview from './ProfileReview'

const ProfileReviews = ({ active }) => {
  const { data: user } = useGetUserQuery()

  const [modalOpen, setModalOpen] = useState(null)
  const closeModal = useCallback(() => setModalOpen(null), [])

  return (
    <div className={`${!active ? 'display-none' : ''}`}>
      <ProfileModalReview
        isOpen={modalOpen == 'review'}
        onClose={closeModal}
      />

      <div className="heading-h2">Ваши отзывы</div>
      <div className="profile-reviews">
        {user?.customer?.reviews?.length > 0 ? user.customer.reviews.map((review) => (
          <ProfileReview key={review.id} review={review} />
        )) : 'Пока нет отзывов'}
        <div>
          <button className="button-big-primary-wide" onClick={() => setModalOpen('review')}>Оставить отзыв</button>
        </div>
      </div>
    </div>
  )
}

export default ProfileReviews