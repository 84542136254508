import { Clusterer, useYMaps } from "@pbe/react-yandex-maps"
import MapPlacemark from "./MapPlacemark"

const MapClusterer = ({ placemarks, onSelect }) => {
  const ymaps = useYMaps(['templateLayoutFactory'])

  if (!ymaps || !ymaps.templateLayoutFactory) {
    return null
  }

  return (
    <Clusterer
      options={{
        groupByCoordinates: false,
        clusterIconLayout: ymaps.templateLayoutFactory.createClass(
          '<div class="yandex-map-cluster">{{ properties.geoObjects.length }}</div>'
        ),
        clusterIconShape: {
          type: 'Rectangle',
          coordinates: [[0, 0], [54, 54]],
        },
      }}
    >
      {placemarks.map((elem, index) => (
        <MapPlacemark
          key={index}
          ymaps={ymaps}
          onSelect={onSelect}
          latitude={elem.location.latitude}
          longitude={elem.location.longitude}
          name={elem.name}
          address={elem?.address}
          workTime={elem?.workTime}
          addressFull={elem?.addressFull}
          destinationStationId={elem?.destinationStationId}
        />
      ))}
    </Clusterer>
  )
}

export default MapClusterer