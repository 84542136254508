import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  filters: [],
  sort: null,
}

// If you are not using async thunks you can use the standalone `createSlice`.
export const catalogFiltersSlice = createSlice({
  name: "catalogFilters",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: create => ({
    catalogFiltersToggled: create.reducer((state, action) => {
      const existingFilter = state.filters.find(filter => filter.category === action.payload.category && filter.optionId === action.payload.optionId)
      if (existingFilter) {
        state.filters = state.filters.filter(filter => !(filter.category === action.payload.category && filter.optionId === action.payload.optionId))
        return
      }
      state.filters.push(action.payload)
    }),
    catalogFiltersCleared: create.reducer((state, action) => {
      state.filters = []
    }),
    catalogSortSelected: create.reducer((state, action) => {
      state.sort = action.payload
    }),
    catalogSortCleared: create.reducer((state, action) => {
      state.sort = null
    }),
  }),
  selectors: {
    selectCatalogFilters: state => state.filters,
    selectCatalogSort: state => state.sort,
  },
})

export const { catalogFiltersToggled, catalogFiltersCleared, catalogSortSelected, catalogSortCleared } = catalogFiltersSlice.actions

export const { selectCatalogFilters, selectCatalogSort } = catalogFiltersSlice.selectors