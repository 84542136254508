import { useState } from 'react'
import { setAsDefaultCustomerAddress, deleteCustomerAddress } from '../../utils/api'

const ProfileAddress = ({ address, handleEditAddress, refetch }) => {
  const [loading, setLoading] = useState(false)

  const handleSetDefaultAddress = async (id) => {
    if (loading !== false) return
    setLoading(id)
    await setAsDefaultCustomerAddress(id)
    await refetch()
    setLoading(false)
  }

  const handleDeleteAddress = async (id) => {
    if (loading !== false) return
    setLoading(id)
    await deleteCustomerAddress(id)
    await refetch()
    setLoading(false)
  }

  return (
    <div className="profile-address">
      <div className="profile-address-name">
        <div>{address.name}</div>
        <img src="/images/utils/pencil.png" alt="" onClick={() => handleEditAddress(address)} />
      </div>
      {address.is_default == 1 && <div className="profile-address-default">По умолчанию</div>}
      {address.is_default == 0 && <div><div className="link-action" onClick={() => handleSetDefaultAddress(address.id)}>{loading == address.id ? 'Загрузка...' : 'Сделать по умолчанию'}</div></div>}
      <div>{address.address}</div>
      <div>{address.entrance && `Подъезд ${address.entrance},`} {address.floor && `Этаж ${address.floor},`} {address.apartment && `Квартира ${address.apartment},`} {address.intercom && `Домофон ${address.intercom}`}</div>
      <div className="profile-address-actions">
        <div className="link-action" onClick={() => handleEditAddress(address)}>Изменить</div>
        <div className="link-action" onClick={() => handleDeleteAddress(address.id)}>{loading == address.id ? 'Загрузка...' : 'Удалить адрес'}</div>
      </div>
    </div>
  )
}

export default ProfileAddress