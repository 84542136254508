import { createSlice } from "@reduxjs/toolkit"

const initialState = null

// If you are not using async thunks you can use the standalone `createSlice`.
export const lastOrderSlice = createSlice({
  name: "lastOrder",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: create => ({
    lastOrderSet: create.reducer((state, action) => {
      return action.payload
    }),
  }),
  selectors: {
    selectLastOrder: state => state,
  },
})

export const { lastOrderSet } = lastOrderSlice.actions

export const { selectLastOrder } = lastOrderSlice.selectors