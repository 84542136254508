import { memo } from 'react'
import Modal from '../../Modal'
import { stores } from '../../../data/data.js'
import { YMaps, Map } from "@pbe/react-yandex-maps"
import MapClusterer from './Map/MapClusterer'

const CartFormModalPickupStore = ({ isOpen, onClose, onSelect, validateField }) => {
  return (
    <Modal heading="Кофейни" isOpen={isOpen} onClose={onClose}>
      <div className="yandex-map-wrapper">
        <YMaps>
          <Map
            state={{
              center: [
                55.75322,
                37.622513,
              ],
              zoom: 10,
            }}
            width="100%"
            height="500px"
          >
            <MapClusterer
              placemarks={stores}
              onSelect={(placemark) => {
                onSelect(placemark.name)
                validateField(placemark.name)
                onClose()
              }}
            />
          </Map>
        </YMaps>
      </div>
    </Modal>
  )
}

export default memo(CartFormModalPickupStore)