import { memo } from 'react'
import { deliveryTypes } from '../../../utils/enums'
import { getDeliveryPriceDiscount } from '../../../utils/functions'
import CartFormFieldRadioDeliveryType from './CartFormFieldRadioDeliveryType'

const CartFormDeliveryType = ({ deliveryTypeVariants, deliveryType, onChange, city, cartItemsTotalSum, deliveryPrices, deliveryTimes }) => {
  const formatDeliveryPrice = (price, deliveryType) => {
    if (price === false) return '(недоступно)'
    if (price === null || typeof(price) === 'undefined') return 'Загрузка...'

    price = price - getDeliveryPriceDiscount(deliveryType, city, cartItemsTotalSum)
    if (price < 0) price = 0

    return price > 0 ? <>от {price} <span className="commissioner-400">₽</span></> : <>0 <span className="commissioner-400">₽</span></>
  }

  const formatDeliveryTime = (time) => {
    if (time === false) return 'недоступно'

    return (time != null && typeof(time) != 'undefined') ? time : 'Загрузка...'
  }

  return (
    <>
      {deliveryTypeVariants?.length > 0 && deliveryTypeVariants.map((type, key) => {
        const current = deliveryTypes.find(elem => elem.type === type)
        if (deliveryPrices[type] === false) return null
        return <CartFormFieldRadioDeliveryType
          key={key}
          active={deliveryType == type}
          label={current.label}
          info={formatDeliveryTime(deliveryTimes[type])}
          price={formatDeliveryPrice(deliveryPrices[type], deliveryType)}
          note={current?.note}
          onClick={() => onChange(type)}
        />
      })}
    </>
  )
}

export default memo(CartFormDeliveryType)